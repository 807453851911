import React from 'react'
import TranslatingProgressbar from '../TranslatingProgressbar'
import { IDocumentTranslatorState } from 'interfaces/dt.interface';
import { useTranslation } from 'react-i18next';
import { Back } from '../SVGs';
interface IProps{
  nextClick:Function
  state?: IDocumentTranslatorState;
  setState?: Function;
  progress:number;
  mtype?:string
  onClose?: any;
}
const TranslatingDocument = ({ nextClick, progress, mtype, onClose }: IProps) => {
  const { t } = useTranslation('translation');
  return (
    <div className=' p-3'>
      {mtype === "summary_url" && <div className=' flex mb-3 items-center font-extrabold'><button onClick={onClose}><Back /></button><span className=' ml-5'>{t("summarizing_url")}</span></div>}
    <div className=' bg-[#F3F5F7] dark:bg-n-5 w-full h-[288px] rounded-[20px] flex flex-col justify-center items-center mb-8 min-w-96'>
    <TranslatingProgressbar completed={progress}/>
        <h5 className='h5 mt-6'>{mtype == 'summary' ? 'Processing Document' : mtype === "audio-summary" ? t('summarizing_document') : mtype == 'summary_url' ? t('creating_summary') : 'Translating Document'}</h5>
    <span className='text-[#838587] !font-mont'>{t("please_wait_a_moment")}</span>
    </div>
      <button disabled className='btn btn-accent w-full bg-[#D84C10]' onClick={() => { nextClick() }}>{t("cancel")} {mtype == 'summary' || mtype == 'audio-summary' || mtype == 'summary_url' ? t("summarizing") : t("translating")}</button>
   </div>
  )
}

export default TranslatingDocument