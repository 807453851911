import React, { useEffect, useState } from "react";
import Modal from "@/components/Modal";
import UploadDocument from "../UploadDocument";
import toast from "react-hot-toast";
import axios from "axios";
import Config from "../../config/index";
import {
  setresponse,
  // setcurStep,
} from "store/slices/TranslationSlice";
import Notify from "../Notify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UploadingDocument from "../UploadingDocumentModal";
import { addSummaryDocument } from "store/slices/ChatGptSlice";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import { isEmpty } from "lodash";

type UploadDocumentModalProps = {
  visible: boolean;
  onClose: any;
  fromGuest?: string;
  setIsLoading?: any;
};

const ChatWithDocModal = ({
  visible,
  onClose,
  fromGuest,
  setIsLoading,
}: UploadDocumentModalProps) => {
  const [payload, setpayload] = useState<any>({
    file: "",
  });
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const { t:lt } = useTranslation("translation");

  const { response, payloadData } = useSelector(
    (state: any) => state.translationStore.translations
  );
  const chatData = useSelector((state: any) => state.chatGptStore);
  const { pathname, query } = router;

  // console.log("");

  // const [response, setresponse] = useState<any>({});
  const [translateProgress, settranslateProgress] = useState<number>(0);
  const [curStep, setcurStep] = useState<number>(1);

  useEffect(() => {
    if (
      fromGuest === "chat_with_doc" &&
      !chatData?.createChat?.data?.messages?.length
    ) {
      onClose(true);
    }
  }, [fromGuest]);

  const changeStepHandler = (toBack: boolean = false) => {
    if (toBack) {
      if (curStep != 1) {
        setcurStep((ps: number) => ps - 1);
      }
    } else {
      if (curStep != 4) {
        setcurStep((ps: number) => ps + 1);
      }
    }
  };

  useEffect(() => {
    if (curStep === 4 && !response) {
      setcurStep(1);
    }
  }, [response, curStep]);

  const showToast = (msg: any, err?: boolean) => {
    const icon = err == true ? "iconError" : "iconCheck";
    toast(
      (t) => (
        //@ts-ignore
        <Notify className="md:flex-col md:items-center md:px-10" iconError>
          <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">{msg}</div>
        </Notify>
      ),
      { duration: 5000 }
    );
  };

  const initTranslation = async () => {
    if (!payload.file) {
      showToast("File is required.");
      return;
    }
    if (payload.file?.size < 1024) {
      showToast("File size should be greater than 1kb");
      changeStepHandler(true);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      if (
        pathname === ROUTES.DOCUMENT_PROCESSING ||
        pathname?.includes("/features")
      ) {
        if (!isEmpty(chatData.createChat.data?.id)) {
          formData.append("chatId", chatData.createChat.data?.id);
        }
      } else if (
        query.id &&
        (pathname === ROUTES.CHAT_ID || pathname === ROUTES.CHAT_LIST_ID)
      ) {
        //@ts-ignore
        formData.append("chatId", router?.query?.id);
      }
      changeStepHandler();
      settranslateProgress(34);
      const res = await axios.post(
        `${Config.baseApiUrl}/chat-with-doc/generate`,
        formData,
        {
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            settranslateProgress(Math.ceil(percentCompleted / 1.2));
          },
        }
      );
      if (res?.status == 200) {
        settranslateProgress(100);
        setresponse(res.data);
        changeStepHandler();
        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6">{lt("uploaded_successfully")}</div>
            </Notify>
          ),
          { duration: 3000 }
        );
        dispatch(addSummaryDocument(res.data));
        setcurStep(1);
        setIsLoading(true);
        settranslateProgress(0);
        onClose(false);
      } else {
        showToast("Failed to upload.", true);
        setcurStep(1);
      }
    } catch (error: any) {
      showToast(
        error?.response?.data?.message ??
          error?.data?.message ??
          error?.message ??
          "Failed to upload due to Unknown reason."
      );
      setcurStep(1);
    }
  };

  return fromGuest === "chat_with_doc" && visible ? (
    <div
      className=" absolute -top-[28rem] left-[40%] z-50 bg-n-2 rounded-3xl dark:bg-n-7 p-5 "
      style={{ pointerEvents: "auto" }}
    >
      {curStep == 1 && (
        <UploadDocument
          nextClick={initTranslation}
          state={payload}
          setState={(data: any) => {
            setpayload(data);
          }}
          description={t("supprted_formats")}
        />
      )}
      {curStep == 2 && (
        <UploadingDocument
          nextClick={changeStepHandler}
          state={payload}
          setState={(data: any) => {
            setpayload(data);
          }}
          progress={translateProgress}
        />
      )}
    </div>
  ) : (
    <div
      className="max-w-[43rem] relative group"
      // classButtonClose="absolute top-3 right-3 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
      // visible={visible}
    >
      <button
        className="text-large absolute right-[30px] top-[20px] z-[1000] opacity-0 group-hover:opacity-100 text-white transition-opacity duration-200"
        onClick={() => {
          //  setcurStep(1); setpayload({ targetLanguage: '', file: '' }); settranslateProgress(0);
          onClose(false);
        }}
      >
        ✕
      </button>
      {curStep == 1 && (
        <UploadDocument
          nextClick={initTranslation}
          state={payload}
          setState={(data: any) => {
            setpayload(data);
          }}
          description={t("supprted_formats")}
        />
      )}
      {curStep == 2 && (
        <UploadingDocument
          nextClick={changeStepHandler}
          state={payload}
          setState={(data: any) => {
            setpayload(data);
          }}
          progress={translateProgress}
        />
      )}
    </div>
  );
};

export default ChatWithDocModal;
