import React, { useEffect, useState } from "react";
import Modal from "@/components/Modal";
import UploadDocument from "./uploadDocument";
import toast from "react-hot-toast";
import Notify from "../Notify";
import { useDispatch, useSelector } from "react-redux";
import { getVoicesList } from "store/actions/voicesList.actions";
import AudioSettings from "./AudioSettings";
import axios from "axios";
import { setIsAudioGenerated } from "store/slices/TextToSpeechSlice";
import { generateAudio } from "store/actions/audio.actions";
import TranslatingDocument from "../TranslatingDocument";
import { setFromAudioSummary } from "store/slices/AudioSlice";
import {
  setLanguageDetails,
  setSummarizationProgress,
  setcurrentSummaryStep,
} from "store/slices/AudioSummarySlice";
import { useTranslation } from "react-i18next";

type UploadDocumentModalProps = {
  visible: boolean;
  onClose: () => void;
  setAudioRes?: any;
};

const AudioSummaryModal = ({
  visible,
  onClose,
  setAudioRes,
}: UploadDocumentModalProps) => {
  const [payload, setpayload] = useState<any>({
    file: "",
  });
  const { curStep, summarizationProgress, detectedLanguage } = useSelector(
    (state: any) => state.AudioSummarySlice
  );
  const { t } = useTranslation("translation");
  const { t:lt } = useTranslation("translation");
  const [minutes, setMinutes] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [vList, setVList] = useState<any>([]);
  const dispatch: any = useDispatch();

  const setcurStep = (val: number) => {
    dispatch(setcurrentSummaryStep(val));
  };
  useEffect(() => {
    dispatch(getVoicesList())
      .unwrap()
      .then((res: any) => {
        setVList([...res.data]);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const lans = getLanguages(detectedLanguage);
    lans && dispatch(setLanguageDetails(lans));
    setLoading(false);
  }, [detectedLanguage]);

  const getLanguages = (language: string = "english") => {
    const detectedLanguages = vList?.filter((lang: any) =>
      lang?.languageName?.toLowerCase().includes(language?.toLowerCase())
    );
    return detectedLanguages &&
      detectedLanguages.length &&
      detectedLanguages.length > 0
      ? detectedLanguages
      : null;
  };

  const changeStepHandler = (toBack: boolean = false) => {
    if (toBack) {
      if (curStep != 1) {
        setcurStep(curStep - 1);
      }
    } else {
      if (curStep != 3) {
        setcurStep(curStep + 1);
      }
    }
  };

  const showToast = (msg: any, err?: boolean) => {
    const icon = err == true ? "iconError" : "iconCheck";
    toast(
      (t) => (
        //@ts-ignore
        <Notify className="md:flex-col md:items-center md:px-10" iconError>
          <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">{msg}</div>
        </Notify>
      ),
      { duration: 5000 }
    );
  };

  const onNextPress = async () => {
    if (!payload.file) {
      showToast("File is required.");
      return;
    }
    if (payload.file?.size < 1024) {
      showToast("File size should be greater than 1kb");
      changeStepHandler(true);
      return;
    }
    if (!minutes) {
      showToast("Minutes should must be selected");
      changeStepHandler(true);
      return;
    }
    try {
      changeStepHandler();
    } catch (error: any) {
      showToast(
        error?.response?.data?.message ??
          error?.data?.message ??
          error?.message ??
          "Failed to upload due to Unknown reason."
      );
      setcurStep(1);
    }
  };
  const handleGenerateAudio = async (value: string, audioSettings: any) => {
    try {
      setLoading(true);
      const audioData: any = {
        text: value,
        gender: audioSettings?.gender?.value,
      };
      toast(
        (t) => (
          <Notify iconError>
            <div className="mr-6 ml-3 h6">
            {lt("your_audio_is_being_generated_please_wait")}
            </div>
          </Notify>
        ),
        { duration: 3000 }
      );
      audioData.voiceName = audioSettings?.voice?.name;
      audioData.voiceMode = "default";
      audioData.language = audioSettings?.language.code;
      audioData.speed = audioSettings?.speed.value || "1";
      audioData.speakerName = audioSettings?.voice?.title;
      dispatch(setIsAudioGenerated(false));
      dispatch(setFromAudioSummary(true));
      dispatch(generateAudio(audioData))
        .unwrap()
        .then((response: any) => {
          console.log(response.data);
          setLoading(false);
          setAudioRes(response.data);
        });
      setTimeout(() => {
        onClose();
        dispatch(setSummarizationProgress(0));
        setcurStep(1);
      }, 4000);
    } catch (error) {
      setLoading(false);
      dispatch(setFromAudioSummary(false));
      setcurStep(1);
      dispatch(setSummarizationProgress(0));
    }
  };
  const initSummary = async (audioSettings: any) => {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("minutes", minutes?.value?.toString());
      setcurStep(3);
      const res = await axios.post(
        `https://dev-summarizer.lokichat.com/upload_book_audio`,
        formData,
        {
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch(
              setSummarizationProgress(Math.ceil(percentCompleted / 1.2))
            );
          },
        }
      );
      if (res?.status == 200) {
        console.log("i am here to check", res);

        dispatch(setSummarizationProgress(100));
        handleGenerateAudio(res.data.summary, audioSettings);
        // toast((t) => (
        //     <Notify iconCheck>
        //         <div className="h6">Uploaded successfully!</div>
        //     </Notify>
        // ), { duration: 3000 });
        // setcurStep(4)
        onClose();
      } else {
        showToast("Failed to summarize.", true);
        setcurStep(1);
      }
    } catch (error: any) {
      showToast(
        error?.response?.data?.message ??
          error?.data?.message ??
          error?.message ??
          "Failed to upload due to Unknown reason."
      );
      setcurStep(1);
    }
  };

  return (
    <div
      className="max-w-[43rem] relative group"
      // classWrap="max-w-[43rem] p-8"
      // classButtonClose="absolute top-5 right-5 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
      // visible={visible}
      // onClose={() => {
      //     //  setcurStep(1); setpayload({ targetLanguage: '', file: '' }); settranslateProgress(0);
      //     onClose();
      // }}
      // nonClosed={true}
    >
      <button
        className="text-large absolute right-[30px] top-[20px] z-[1000] text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200"
        onClick={() => {
          //  setcurStep(1); setpayload({ targetLanguage: '', file: '' }); settranslateProgress(0);
          onClose();
        }}
      >
        ✕
      </button>
      {curStep == 1 && (
        <>
          <UploadDocument
            nextClick={onNextPress}
            state={payload}
            setState={(data: any) => {
              setpayload(data);
            }}
            minutes={minutes}
            setMinutes={setMinutes}
            description={""}
            loading={loading}
          />
        </>
      )}
      {curStep == 2 && <AudioSettings onNext={initSummary} />}
      {curStep == 3 && (
        <TranslatingDocument
          nextClick={changeStepHandler}
          progress={summarizationProgress}
          mtype="audio-summary"
        />
      )}
    </div>
  );
};

export default AudioSummaryModal;
