import React, { useState } from "react";
import Image from "next/image";
import { IDocumentTranslatorState } from "interfaces/dt.interface";
import SignUpModal from "../Modal/SignUpModal";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import { loadDecryptedValue } from "lib/encryption";
import { USER_LOCAL } from "@/constants/user";
import { useTranslation } from "react-i18next";

interface IProps {
  nextClick?: Function;
  state?: IDocumentTranslatorState;
  setState?: Function;
  downloadFile?: Function;
  data?: any;
  mtype?: string;
  title?: string;
  desc?: string;
  btnTitle?: string;
}
const SuccessFullyTranslated = ({
  nextClick,
  data,
  title,
  desc,
  downloadFile,
  btnTitle,
  mtype,
}: IProps) => {
  const [visible, setVisible] = useState(false);
  const router = useRouter();
  const {t}= useTranslation("translation");
  return (
    <>
      <div className=" bg-[#F3F5F7] dark:bg-n-5 max-w-[592px] h-[288px] m-auto rounded-[20px] flex flex-col justify-center items-center mb-8 z-10 min-w-96">
        <Image
          src={"https://lokichatdev.blob.core.windows.net/images/success.svg"}
          height={70}
          width={70}
          alt="success"
        />
        <h5 className="h5 mt-6">{title ?? "Successfully Translated"}</h5>
        <span className="text-[#838587] block max-w-[362px] text-center !font-mont">
          <div>
            {desc ??
              `Congratulations, your uploaded document has been successfully ${
                mtype === "summary" ? "summarized." : "translated."
              }`}
          </div>
        </span>
      </div>
      {btnTitle ? (
        <button
          className="btn btn-blue w-full "
          onClick={() => {
            if (downloadFile) {
              downloadFile(data?.translatedDocumentUrl);
            } else {
              if (nextClick) {
                nextClick();
              }
            }
          }}
        >
          {t("continue")}
        </button>
      ) : (
        mtype != "summary" && (
          <button
            className="btn btn-blue w-full "
            onClick={() => {
              if(router.pathname?.includes("/features")){
                const session = loadDecryptedValue(USER_LOCAL.LOCAL);
                if(session){
                  router.push(ROUTES.CHAT)
                }else{
                  setVisible(true);
                }
              }else if (downloadFile) {
                downloadFile(data?.translatedDocumentUrl);
              }
            }}
          >
          {t("download_document")}
          </button>
        )
      )}
      <SignUpModal visible={visible} onClose={() => setVisible(false)} navigateTo={ROUTES.CHAT}/>
    </>
  );
};

export default SuccessFullyTranslated;
