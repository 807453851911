export const ROUTES = {
  HOME: "/",
  CHAT: "/document-translating",
  PROFILE: "/profile",
  CHAT_ID: "/chat/[id]",
  CHAT_LIST: "/chat-list",
  CHAT_LIST_ID: "/chat-list/[id]",
  CHECKOUT: "/checkout",
  AUDIOLIBRARY: "/audio-library",
  EDUCATION_FEEDBACK: "education-feedback",
  IMAGE_LIBRARY: "/image-library",
  TRACKER: "/tracker",
  SIGNIN: "/sign-in",
  THANKYOUCREATTINGACCOUNT: "/thank-you-creating-account",
  REFERRALCODE: "/referral-code",
  PRICINGHOME: "/pricing-home",
  PRICING: "/pricing",
  UPDATESANDFAQS: "/updates-and-faq",
  GOAL_MANAGEMENT: "/goal-management",
  REFERRAL_MANAGEMENT: "/refferral-management",
  PHOTO_GENERATION: "/photo-generation",
  DEBATE: "/debate",
  DEBATE_ID: "/debate/[id]",
  Invite_Super_User: "/invite-super-user",
  PLAY_AUDIO: "/play-audio/[id]",
  TRANS_LIBRARY: "/transcription-library",
  SUPER_USER: "/super-user",
  LEGAL_NOTICE: "/legal-notice",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIES_POLICY: "/cookies-policy",
  TEXT_SPEECH: "/text-to-speech",
  DOCUMENT_TRANSLATING: "/document-translating",
  DOCUMENT_PROCESSING: "/document-processing",
  HOW_IT_WORK: "/how-it-work",
  AFFILIATE_ID: "/affiliate/[id]",
  TRANSCRIPTION: "/transcription",
  PROFILES: "/profiles",
  THANKS: "/thanks",
  FEATURES: "/features",
};
