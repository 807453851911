import React, { useMemo, useState } from 'react'
import { Listbox, Transition } from "@headlessui/react";
import Icon from '@/components/Icon';
import { useTranslation } from 'react-i18next';
import { useColorMode } from '@chakra-ui/color-mode';
import { languages } from '@/constants/audio';
import languageData from "@/constants/language";
type DropdownInput = {
    selectedLanguage: string;
    setSelectedLanguage: any;
};
function DropdownInput({ selectedLanguage, setSelectedLanguage }: DropdownInput) {
    const [filter, setFilter] = useState<string>("");
    const { t } = useTranslation('translation')
    const { colorMode } = useColorMode();
    const isDarkMode = colorMode === "dark";

    const filteredLanguages = useMemo(() => {
        return languageData.filter(language =>
            language.name.toLowerCase().includes(filter.toLowerCase())
        );
    }, [filter]);

   
    return (
        <Listbox value={selectedLanguage} onChange={setSelectedLanguage}>
            {({ open }) => (
                <>
                    <Listbox.Button className="relative w-full py-1 pl-3 pr-10 text-left cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-10 flex items-center">
                        <span className='mr-3 !font-mont font-semibold sm:text-xs'>{selectedLanguage ?? t('choose_language')}</span>
                        <Icon name={'arrow-down'} fill={isDarkMode ? '#FEFEFE' : '#232627'} />
                    </Listbox.Button>
                    <Transition
                        show={open}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Listbox.Options className="absolute py-1 mt-1 overflow-auto text-base bg-white dark:bg-n-5 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                            <div className="px-3 py-2 relative">
                                <Icon
                                    name={'search'} className=" absolute top-4 left-5" fill="#A6A9A9" />
                                <input
                                    value={filter}
                                    type="text"
                                    placeholder={t("search_for_a_language")}
                                    className="w-full p-2 border  rounded-md focus:ring-2 focus:ring-indigo-500 pl-10 border-[#A6A9A9] bg-transparent ml-1"
                                    onChange={(e) => setFilter(e.target.value)}
                                />
                            </div>
                            <div className={`grid  ${filter == "" ? "md:grid-cols-1  grid-cols-2 " : ""}`}>
                                {filteredLanguages.map((language, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({ active }) =>
                                            `cursor-default select-none relative py-2 pl-4 pr-4 ${index%2 == 0 ? "border-r" : ""} md:border-r-0 ${active ? ' bg-sky-100 dark:bg-slate-300' : 'text-gray-900'}`
                                        }
                                        value={language.name}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'} text-sm text-black dark:text-[#FEFEFE]`}>
                                                {language.name}
                                                </span>
                                                {selected ? (
                                                    <span className="absolute !font-mont inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                        {/* Icon or checkmark if needed */}
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </div>
                        </Listbox.Options>
                    </Transition>
                </>
            )}
        </Listbox>
    )
}

export default DropdownInput