import "@/styles/globals.css";
import Head from "next/head";
import "dotenv/config";
import type { AppProps } from "next/app";
import { Toaster, resolveValue } from "react-hot-toast";
import { Inter, Karla } from "next/font/google";
import { ColorModeScript, ColorModeProvider } from "@chakra-ui/color-mode";
import { useEffect, useMemo, useState } from "react";
import { persistor } from "store";
import { PersistGate } from "redux-persist/integration/react";
import { useStore, useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { resetAllUserData, setGuestUser } from "store/slices/AuthSlice";
import {
  resetAddToLiveNotebook,
  resetAllChatsData,
  resetChatLoadingAndError,
  resetDeletedNotebook,
  setStopGenerateChat,
} from "store/slices/ChatGptSlice";
import { resetAllAudioData, setPlayingAudio } from "store/slices/AudioSlice";
import {
  resetPhotoGenerations,
  resetPhotoLoadingAndError,
} from "store/slices/PhotoGenerationSlice";
import ThemeLayout from "@/components/ThemeLayout";
import { LandingRoutes } from "routes";
import { encryptAndSave, loadDecryptedValue } from "lib/encryption";
import { USER_LOCAL } from "@/constants/user";
import { ROUTES } from "@/constants/routes";
import { io } from "socket.io-client";

import { SOCKET_EVENT_TYPES } from "@/constants/notifications";
import Config from "../config";
import { config } from "dotenv";
import { ISession } from "interfaces/auth.interface";
import { clarity } from "react-microsoft-clarity";
import Layout from "@/components/Layout";
import { parseQueryParams } from "lib/getQueryParamValue";
import { getMineNotifications } from "store/actions/notification.actions";
import { getInprogressAudios } from "store/actions/audio.actions";
import AudioDraggable from "@/components/AudioDraggable";
import { resetAllDebateData } from "store/slices/DebateSlice";
import { initializei18n } from "../services/i18n";
import CustomErrorBoundary from "../components/ErrorBoundary";
import { isEmpty, size } from "lodash";
import Script from "next/script";
import ReactGA from "react-ga";
//@ts-ignore
import FreeTrialModal from "@/components/Modal/FreeTrialModal";
import Modal from "@/components/Modal";
import { resetAudioSummary } from "store/slices/AudioSummarySlice";
import authTranslation from "../public/locales/en-US/auth.json";
import otherTranslation from "../public/locales/en-US/translation.json";
import { SOCKET_OPTIONS, SOCKET_URL } from "@/config/socketConfig";
import FreeTrialThreeDaysModal from "@/components/Modal/FreeTrialThreeDaysModal";
import { setPresistColorMode } from "store/slices/PreferenceSlice";
import { languageActions } from "store/actions/language.actions";

// const socket = io(Config.backendBaseUrl, {});

const socket = io(SOCKET_URL, SOCKET_OPTIONS);

const inter = Inter({
  weight: ["500", "600", "700"],
  subsets: ["latin"],
  display: "block",
  variable: "--font-inter",
});

const karla = Karla({
  weight: ["400", "700"],
  subsets: ["latin"],
  display: "block",
  variable: "--font-karla",
});
// const mont = Montserrat({
//   weight: ["400", "800"],
//   subsets: ["latin"],
//   display: "block",
//   variable: "--font-mont",
// });

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const store: any = useStore();
  const dispatch: any = useDispatch();

  const { pathname } = router;

  const [toggle, setToggle] = useState<boolean>(true);
  const [role, setRole] = useState<string>("");
  const [audio, setAudio] = useState<any>(null);

  const userData = useSelector((state: any) => state.authStore);
  const { selectedModal } = useSelector((state: any) => state.chatGptStore);
  const { user, fiftyPercentOff } = userData;
  const isPersistDataLoaded = useSelector(
    (state: any) => state._persist.rehydrated
  );
  useEffect(() => {
    console.log("dispatching dark mode");
    // Set the color mode to 'dark' in local storage
    localStorage.setItem("chakra-ui-color-mode", "dark");
    console.log("seeting leanguage to en-US");
    //dispatch(languageActions.setLanguage("en-US"));
    dispatch(setPresistColorMode("dark"));
  }, []);

  useEffect(() => {
    clarity.init("jh3g1r0axm");
    initializei18n();
  }, []);

  useEffect(() => {
    setAudio(new Audio("/not.wav"));
    dispatch(resetAudioSummary());
  }, []);

  useEffect(() => {
    const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
    // console.log("sessions", sessions);
    const session = sessions?.token;
    if (session) {
      socket.onAny((eventName, args) => {
        if (args?.isNotification == true) {
          let isThisUser = args?.userIds?.find(
            (i: any) => i?.toString() == sessions?.userId?.toString()
          );
          if (isThisUser) {
            console.log(eventName, args, "Arguments of logging");
            dispatch(getMineNotifications());
            let aud = new Audio("/not.wav");
            aud.play();
          }
        }
      });
    }
    socket.on(`${SOCKET_EVENT_TYPES.SUMMARY_STATUS}`, (payload) => {
      console.log(payload, " =====> Notification Socket Payload.");
    });
  }, []);

  useEffect(() => {
    try {
      if (user && user.data && user.data.id) {
        console.log("socket dispatched");
        socket.on(
          `${SOCKET_EVENT_TYPES.AUDIO_GENERATED_NOTIFICATION}`,
          (payload) => {
            if (payload?.userId == user?.data?.id) {
              console.log("socket triggered");
              dispatch(getInprogressAudios());
              dispatch(getMineNotifications());
            }
          }
        );
      }
    } catch (error) {}
  }, [user]);

  useEffect(() => {
    const params = router.asPath.split("?");
    if (size(params) > 1) {
      const parsedQueryParams = parseQueryParams(router.asPath.split("?")[1]);
      handleGenerateSession(
        parsedQueryParams?.token,
        parsedQueryParams?.userId,
        parsedQueryParams?.role,
        parsedQueryParams?.refreshToken,
        parsedQueryParams?.isMobile
      );
    } else {
      const { token, userId, role, refreshToken } = router?.query;
      handleGenerateSession(token, userId, role, refreshToken);
    }
  }, [router.query]);
  const handleGenerateSession = (
    token: any,
    userId: any,
    role: any,
    refreshToken: any,
    isMobile: any = false
  ) => {
    if (
      typeof token === "string" &&
      typeof userId === "string" &&
      typeof refreshToken === "string" &&
      typeof role === "string"
    ) {
      const userResponse: ISession = {
        refreshToken: refreshToken,
        userId: userId,
        token: token,
        role: role,
        loginType: role,
        isMobile: isMobile,
      };
      encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(userResponse));
    }
  };

  useEffect(() => {
    if (isPersistDataLoaded) {
      const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
      const session = sessions?.token;
      if (window.location.pathname === ROUTES.SIGNIN && !session) {
        dispatch(resetAllUserData(null));
        dispatch(resetAllChatsData(null));
        dispatch(resetAllAudioData(null));
        dispatch(resetPhotoGenerations(null));
        dispatch(setPlayingAudio(null));
        dispatch(resetAllDebateData(null));
        localStorage.clear();
        router.push(`${ROUTES.SIGNIN}/${window.location.search || ""}`);
      } else if (window.location.pathname === ROUTES.SIGNIN && session) {
        router.push(ROUTES.CHAT);
      }
      if (window.location.pathname !== ROUTES.SIGNIN) {
        dispatch(resetChatLoadingAndError(null));
        dispatch(resetPhotoLoadingAndError(null));
        dispatch(resetAddToLiveNotebook(null));
        dispatch(resetDeletedNotebook(null));
        dispatch(setStopGenerateChat({ keyName: "", value: false }));
      }
    }
  }, [isPersistDataLoaded]);
  const isMobile = loadDecryptedValue(USER_LOCAL.LOCAL)?.isMobile;
  const audioGerationLoading = useSelector(
    (state: any) => state.audioStore.createAudio.draggableShow
  );

  useEffect(() => {
    ReactGA.initialize(Config.googleAnalyticesTID);
  }, []);

  const restrictedRoutes = [
    ROUTES.TRANS_LIBRARY,
    ROUTES.PHOTO_GENERATION,
    ROUTES.UPDATESANDFAQS,
    ROUTES.CHAT,
    ROUTES.CHAT_ID,
    ROUTES.AUDIOLIBRARY,
    ROUTES.CHAT_LIST,
    ROUTES.CHAT_LIST_ID,
    ROUTES.DEBATE,
    ROUTES.DEBATE_ID,
    ROUTES.GOAL_MANAGEMENT,
    ROUTES.IMAGE_LIBRARY,
    ROUTES.TRACKER,
    ROUTES.REFERRAL_MANAGEMENT,
    ROUTES.TEXT_SPEECH,
    ROUTES.DOCUMENT_TRANSLATING,
    ROUTES.DOCUMENT_PROCESSING,
    ROUTES.TRANSCRIPTION,
  ];
  const sevenDaysAdded = new Date(
    new Date(user?.data?.subscribedDate).setDate(
      new Date(user?.data?.subscribedDate).getDate() + 7
    )
  );

  const ThreeDaysAdded = new Date(
    new Date(user?.data?.subscribedDate).setDate(
      new Date(user?.data?.subscribedDate).getDate() + 3
    )
  );
  // console.log("ThreeDaysAdded", ThreeDaysAdded);
  let isFreePlanExpires =
    user?.data?.fullPlanData?.title == "Trial" &&
    sevenDaysAdded < new Date() &&
    restrictedRoutes.includes(router.pathname);

  useEffect(() => {
    setRole(user?.data?.role ?? "");

    isFreePlanExpires =
      user?.data?.fullPlanData?.title == "Trial" &&
      sevenDaysAdded < new Date() &&
      restrictedRoutes.includes(router.pathname);

    if (router.pathname?.includes("/features")) {
      dispatch(setGuestUser(true));
    } else {
      dispatch(setGuestUser(false));
    }
  }, [router.pathname, user?.data, isFreePlanExpires]);

  //////////////////////three days//////////

  const isFreePlanThreeDays = useMemo(() => {
    return (
      user?.data?.fullPlanData?.title === "Trial" &&
      ThreeDaysAdded < new Date() &&
      restrictedRoutes.includes(router.pathname)
    );
  }, [user?.data, router.pathname, ThreeDaysAdded, restrictedRoutes]);
  // console.log("isFreePlanThreeDays", isFreePlanThreeDays);

  useEffect(() => {
    setRole(user?.data?.role ?? "");

    if (router.pathname?.includes("/features")) {
      dispatch(setGuestUser(true));
    } else {
      dispatch(setGuestUser(false));
    }
  }, [router.pathname, user?.data, isFreePlanThreeDays]);

  const isLandingRoute = () => {
    // Regular expression to match /features/slug pattern
    const featuresPattern = /^\/features\/[^/]+$/;

    return LandingRoutes.includes(pathname) || featuresPattern.test(pathname);
  };

  return (
    <>
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap"
          rel="stylesheet"
        />
      </Head>
      <PersistGate
        persistor={store.__persistor}
        loading={<div>Loading...</div>}
      >
        <CustomErrorBoundary>
          <main
          //commented out to resolve Montserrat was not applying
          // className={`${karla.variable} ${inter.variable} ${mont.variable}`}
          >
            {/* Anaylytics */}
            <Script
              strategy="afterInteractive"
              src="https://www.googletagmanager.com/gtag/js?id=G-KS5XBPJXGK"
            />

            <Script id="google-analytics">
              {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-KS5XBPJXGK');
        `}
            </Script>
            <Script id="google-tag-manager" strategy="afterInteractive">
              {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TXRTKWCD');
          `}
            </Script>
            {/* Anaylytics */}
            <style jsx global>{`
              html {
                font-family: ${karla.style.fontFamily};
              }
              #headlessui-portal-root {
                font-family: ${inter.style.fontFamily};
              }
            `}</style>
            <ColorModeProvider>
              <ColorModeScript
                initialColorMode="dark"
                key="chakra-ui-no-flash"
                storageKey="chakra-ui-color-mode"
              />
              {isLandingRoute() ||
              pathname?.includes("play-audio") ||
              pathname === ROUTES.SIGNIN ||
              pathname === ROUTES.REFERRALCODE ||
              pathname === ROUTES.THANKYOUCREATTINGACCOUNT ||
              pathname === ROUTES.AFFILIATE_ID ||
              pathname === ROUTES.Invite_Super_User ? (
                <ThemeLayout>
                  <Component {...pageProps} />
                </ThemeLayout>
              ) : (
                <Layout
                  isMobile={isMobile}
                  toggle={toggle}
                  setToggle={setToggle}
                  goalsidebar={
                    pathname === ROUTES.GOAL_MANAGEMENT ? true : false
                  }
                  documentProcessing={
                    pathname === ROUTES.DOCUMENT_PROCESSING ? true : false
                  }
                  hideRightSidebar={
                    (pathname === ROUTES.CHAT && selectedModal) ||
                    pathname === ROUTES.CHAT_ID ||
                    pathname === ROUTES.CHAT_LIST ||
                    pathname === ROUTES.CHAT_LIST_ID ||
                    pathname === ROUTES.DEBATE_ID ||
                    pathname === ROUTES.PHOTO_GENERATION ||
                    pathname === ROUTES.TEXT_SPEECH ||
                    pathname === ROUTES.DOCUMENT_TRANSLATING ||
                    pathname === ROUTES.DOCUMENT_PROCESSING ||
                    pathname === ROUTES.TRANSCRIPTION ||
                    pathname === ROUTES.AFFILIATE_ID
                      ? false
                      : true
                  }
                >
                  <Component {...pageProps} />
                </Layout>
              )}
              <Toaster
                containerStyle={{
                  bottom: 40,
                  left: 20,
                  right: 20,
                }}
                position="bottom-center"
                gutter={10}
                toastOptions={{
                  duration: 2000,
                }}
              >
                {(t) => (
                  <div
                    style={{
                      opacity: t.visible ? 1 : 0,
                      transform: t.visible
                        ? "translatey(0)"
                        : "translatey(0.75rem)",
                      transition: "all .2s",
                    }}
                  >
                    {resolveValue(t.message, t)}
                  </div>
                )}
              </Toaster>
              {/* {!(
                pathname == ROUTES.CHAT ||
                pathname == "/chat#" ||
                pathname == ROUTES.TEXT_SPEECH ||
                pathname == ROUTES.AUDIOLIBRARY ||
                LandingRoutes.includes(pathname) ||
                audioGerationLoading == false
              ) && <AudioDraggable />} */}
              {/* {!["super-admin", "admin"].includes(role) && (
                <div>
                  {isFreePlanExpires == true && (
                    <Modal
                      classWrap="max-w-7xl"
                      classButtonClose="hidden"
                      visible={isFreePlanExpires}
                      onClose={() => {
                        router.push(ROUTES.PRICING);
                      }}
                    >
                      <FreeTrialModal />
                    </Modal>
                  )}
                </div>
              )} */}
              {/* {!["super-admin", "admin"].includes(role) && (
                <div>
                  {isFreePlanThreeDays == true &&
                    isFreePlanExpires == false &&
                    (fiftyPercentOff?.data === null ||
                      fiftyPercentOff?.data === false) 
                      && (
                      <Modal
                        classWrap="max-w-7xl"
                        classButtonClose="hidden"
                        visible={isFreePlanThreeDays}
                        onClose={() => {
                          router.push(ROUTES.PRICING);
                        }}
                      >
                        <FreeTrialThreeDaysModal />
                      </Modal>
                    )}
                </div>
              )} */}
              {/* <TranscriptionGeneratedDraggable/> */}
            </ColorModeProvider>
          </main>
        </CustomErrorBoundary>
      </PersistGate>
    </>
  );
}

export default persistor.withRedux(App);
