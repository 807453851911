import React, { useState, useEffect } from "react";
import Message from "@/components/Message";
import Menu from "@/components/Menu";
import { translate } from "@/constants/navigation";
import Chat from "@/components/Chat";
import Image from "@/components/Image";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, size } from "lodash";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import Answer from "@/components/Answer";
import Question from "@/components/Question";
import {
  getChatById,
  stopMessageStream,
  updateMessageStream,
} from "store/actions/chat.actions";
import {
  getChatNotebooks,
  addToLiveNotebook,
} from "store/actions/notebook.actions";
import { IAddToLiveNotebookData } from "interfaces/notebook.interface";
import {
  resetAddToLiveNotebook,
  resetChatLoadingAndError,
  resetPromptQuestion,
  setStream,
  setStreamText,
  setUpdateStreamLoading,
  setUpdateStreamText,
  resetStreamError,
  resetUpdateStreamError,
  setSelectedChatModal,
  resetAllChatsData,
  resetChatAndNotebooks,
} from "store/slices/ChatGptSlice";
import { formatDateRelative } from "lib/DateTime";
import { useRouter } from "next/router";
import ChatModelTabs from "@/components/ChatModelTabs";
import { useTranslation } from "react-i18next";
import {
  ABORT_EVENTS,
  CHAT_MODEL_TABS,
  MESSAGE_SENDER,
} from "@/constants/chats";
import { LOKI_LOGO } from "@/constants/debate";
import Markdown from "react-markdown";
import { SOCKET_EVENT_TYPES } from "@/constants/notifications";
import { io } from "socket.io-client";
import { SOCKET_OPTIONS, SOCKET_URL } from "@/config/socketConfig";
import { updateStream as updateStreamAction } from "services/chat.fetch.service";
import { ROUTES } from "@/constants/routes";
import { v4 as uuidv4 } from "uuid";
import { ErrorToast } from "@/constants/toast";
import ProfileCards from "../Profile/Components/ProfileCards";
import { htmlToPlainText, markdownToHtml, removeStartingAndEndingTags } from "lib/chat";
import { setNavigateTo } from "store/slices/AuthSlice";
import { getVoicesList } from "store/actions/voicesList.actions";

const socket = io(SOCKET_URL, SOCKET_OPTIONS);
const { CHAT_LIST } = ROUTES;
const { RE_GENERATE } = ABORT_EVENTS;
const { ASSISTANT } = MESSAGE_SENDER;
const { UPDATE_STREAM_COMPLETION } = SOCKET_EVENT_TYPES;

type MainProps = { setToggle?: any; toggle?: any; fromGuest?:string; docProcessing?: boolean; };

let abortController = new AbortController();

const Main = ({ setToggle, toggle, fromGuest,docProcessing}: MainProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation("translation");
  const navigation = translate(lt);

  const { pathname, query } = router;
  const { id: chatId } = query;

  const chatData = useSelector((state: any) => state.chatGptStore);
  const userData = useSelector((state: any) => state.authStore);
  const selectedQuestion = useSelector(
    (state: any) => state.chatGptStore.promptQuestion
  );

  const {
    stream,
    streamText,
    createChat,
    streamError,
    updateStream,
    storeChatGoalData,
    selectedChatListItem,
    storeMessageStream,
    updateMessageStream: updateMessageStreamData,
    addToLiveNotebook: addToLiveNotebookData,
    selectedModal,
  } = chatData;


  console.log("createChat",createChat);
  
  const { user } = userData;

  const [message, setMessage] = useState<string>("");
  const [messageData, setMessageData] = useState("");
  const [updatePromptData, setUpdatePromptData] = useState<any>({});
  const [isRegenerate, setIsRegenerate] = useState<boolean>(false);
  const [abortRegenerateChat, setAbortRegenerateChat] = useState<any>(null);
  const [selectedChatModel, setSelectedChatModel] = useState<any>();
  const [messagePassCode, setMessagePassCode] = useState("");
  const [showButton, setShowButton] = useState<any>(null);
  const [reply, setReply] = useState<any>("");
  const [selectedText, setSelectedText] = useState<any>("");
  const [contentHistory, setContentHistory] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [hasFileUrl, setHasFileUrl] = useState(false);


  const [index, setIndex] = useState(-1);
console.log("selectedChatModel",selectedChatModel);
  useEffect(() => {
    if (!createChat?.data?.messages?.length) {
      setReply("")
    }
    console.log("createChat-->",createChat?.data?.messages);
    
  }, [createChat?.data])

  useEffect(() => {
    if(userData.navigateTo){
      router.push(userData.navigateTo);
      dispatch(setNavigateTo(null));
    }
  }, [userData.navigateTo])
  

  const handleTextSelect = (id: string, i: any) => {
    console.log("Handle Text Selection");

    const selection: any = window.getSelection();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    const text = selection?.toString();
    if (selection && range && text && text != "") {
      const container = document.createElement("div");
      if (range) {
        container.appendChild(range.cloneContents());
      }
      const selectedHtml = container.innerHTML;
      const text = selection?.toString();


      setShowButton(id);

      if (text && text?.length > 1) {
        setIndex(i);
        setSelectedText(removeStartingAndEndingTags(selectedHtml).replaceAll('<br>', '<br/>'));
      }
    } else {
      // setShowButton(null)
    }
  };

  useEffect(() => {
    const handleMouseDown = () => {
      const selection: any = window.getSelection();
      if (!selection.isCollapsed) {
        setTimeout(() => {
          setShowButton(null);
          // setSelectedText("");
          window.getSelection()?.removeAllRanges()
        }, 500);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (selectedModal) {
      setSelectedChatModel(selectedModal);
    }
  }, [selectedModal]);

  useEffect(() => { }, []);

  useEffect(() => {
    if (storeChatGoalData?.loading) {
      setMessageData("");
    }
  }, [storeChatGoalData]);

  useEffect(() => {
    if (selectedQuestion !== "" && router.pathname !== "/photo-generation") {
      setMessage(selectedQuestion);
      setTimeout(() => {
        dispatch(resetPromptQuestion());
      }, 1000);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (chatId) {
      const data: any = { chatId };
      dispatch(getChatById(data));
      dispatch(getChatNotebooks(data));
    }
  }, [chatId]);

  useEffect(() => {
    if (
      !isEmpty(addToLiveNotebookData.data) &&
      !addToLiveNotebookData.loading
    ) {
      toast(
        (t) => (
          <Notify iconCheck>
            <div className="ml-3 h6 sm:text-sm">{lt("resAdded_notebook")}</div>
          </Notify>
        ),
        { duration: 1000 }
      );
      if (!isEmpty(createChat.data?.id)) {
        dispatch(getChatNotebooks({ chatId: createChat.data.id }));
      }
      setTimeout(() => {
        dispatch(resetChatLoadingAndError(null));
        dispatch(resetAddToLiveNotebook(null));
      }, 3000);
    }
  }, [addToLiveNotebookData]);



  useEffect(() => {
    const btn = document.getElementById(`quotebtn${index}`)
    if (btn) {
      btn.addEventListener("click", function () {
        setReply(htmlToPlainText(selectedText));
        setShowButton(null);
        setSelectedText("");
        // replyHandler();
      });
    }
  }, [selectedText]);

  const handleAddToLiveNotebook = (content: any, chatId: string) => {
    const notebookData: IAddToLiveNotebookData = {
      chatId: chatId,
      response: content.content,
      messageId: content.messageId,
    };
    dispatch(addToLiveNotebook(notebookData));
  };

  const handleRegenerateChat = (
    content: any,
    msgContent: any,
    chatId: string
  ) => {
    const regenerateChatData: any = {
      chatId: chatId,
      message: msgContent.content,
      messageId: content.responseTo,
    };
    setMessagePassCode(content.responseTo);
    regenerateChatData.messagePassCode = uuidv4();
    setUpdatePromptData(content);
    setIsRegenerate(true);

    dispatch(updateMessageStream(regenerateChatData))
      .unwrap()
      .then((res: any) => {
        localStorage.setItem("isUpdate", "true");
        localStorage.setItem("chatId", chatId);
        localStorage.setItem("messageId", content.responseTo);
        dispatch(
          updateStreamAction(regenerateChatData, abortController.signal)
        );
        setAbortRegenerateChat(true);
      })
      .catch((error: any) => {
        ErrorToast(error?.message);
      });
    localStorage.setItem("isRegenerateChat", "true");
  };

  const handleUpdateSocketEvent = (payload: any) => {
    try {
      const updateMessage = payload?.message;
      if (
        !isEmpty(createChat.data) &&
        !updateStream.loading &&
        createChat.data?.id === updateMessage.id
      ) {
        setUpdatePromptData({});
      }
      setIsRegenerate(false);
      localStorage.setItem("isRegenerateChat", "false");
    } catch (error) { }
  };

  useEffect(() => {
    socket.on(
      `${UPDATE_STREAM_COMPLETION}_${user?.data?.id}`,
      handleUpdateSocketEvent
    );

    return () => {
      socket.off(
        `${UPDATE_STREAM_COMPLETION}_${user?.data?.id}`,
        handleUpdateSocketEvent
      );
    };
  }, [handleUpdateSocketEvent]);

  useEffect(() => {
    if (
      chatData.stopGenerateChat?.keyName === RE_GENERATE &&
      chatData.stopGenerateChat?.value &&
      abortRegenerateChat
    ) {
      console.log("Called-5");
      setAbortRegenerateChat(false);
      abortController.abort("Chat Paused with user permission");
      abortController = new AbortController();
      localStorage.setItem("isRegenerateChat", "false");
      setUpdatePromptData({});
      const data = {
        messageId: messagePassCode,
        chatId: createChat.data?.id ?? null,
        isUpdate: true,
      };
      if (!isEmpty(createChat?.data?.id)) {
        dispatch(stopMessageStream(data))
          .unwrap()
          .then((res: any) => {
            console.log("test", res);
            dispatch(resetChatLoadingAndError(null));
          });
      }
    }
  }, [chatData.stopGenerateChat]);

  useEffect(() => {
    if (stream && size(streamText) >= 0) {
      dispatch(setStream(false));
      dispatch(setStreamText(""));
    }
    if (updateStream.loading && size(updateStream.text) >= 0) {
      dispatch(setUpdateStreamLoading(false));
      dispatch(setUpdateStreamText(""));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(createChat.data?.id)) {
      const data: any = { chatId: createChat.data?.id };
      
      dispatch(getChatById(data));
      dispatch(getChatNotebooks(data));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(streamError)) {
      toast(
        (t) => (
          <Notify iconDelete>
            <div className="ml-3 h6 sm:text-sm">{streamError}</div>
          </Notify>
        ),
        { duration: 2000 }
      );
      setTimeout(() => {
        dispatch(resetStreamError(null));
      }, 3000);
    }
  }, [streamError]);

  useEffect(() => {
    if (!isEmpty(updateStream?.error)) {
      setUpdatePromptData({});
      toast(
        (t) => (
          <Notify iconDelete>
            <div className="ml-3 h6 sm:text-sm">{updateStream?.error}</div>
          </Notify>
        ),
        { duration: 2000 }
      );
      setTimeout(() => {
        dispatch(resetUpdateStreamError(null));
      }, 3000);
    }
  }, [updateStream]);

  useEffect(() => {
    const beforeUnloadHandler = async (event: BeforeUnloadEvent) => {
      const confirmationMessage = "Are you sure you want to leave?";

      // Check the condition for background processes
      if (stream || updateStream.loading) {
        const chatId = localStorage.getItem("chatId");
        const isUpdate = localStorage.getItem("isUpdate");
        const messageId = localStorage.getItem("messageId");

        // Process only if chatId and messageId are present
        if (chatId && messageId) {
          const data = {
            chatId,
            messageId,
            isUpdate: isUpdate === "true" ? true : false,
          };

          // Stop the background process and wait for it to finish
          try {
            await dispatch(stopMessageStream(data))
              .unwrap()
              .then(() => {
                localStorage.removeItem("chatId");
                localStorage.removeItem("isUpdate");
                localStorage.removeItem("messageId");
              });
          } catch (error) {
            console.error("Error stopping the message stream:", error);
          }
        }
      }

      // Reload the page
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    // Attach the beforeunload event listener
    window.addEventListener("beforeunload", beforeUnloadHandler);

    // Cleanup localStorage and remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [stream, updateStream]);

  const showAnswerData = (history: any) => {
    if (history.sender === ASSISTANT) {
      if (size(history?.content?.trim()) === 0) {
        if (
          isEmpty(history.messageId) ||
          (history.messageId === updatePromptData.messageId &&
            history.responseTo === updatePromptData.responseTo) ||
          history.responseTo === updatePromptData.messageId
        ) {
          return true;
        }
        return false;
      }
      return true;
    } else return false;
  };

  const showAnswerDataLoading = (history: any) => {
    const isUpdateLoading =
      updateMessageStreamData.loading || updateStream.loading;
    return (
      (!isEmpty(history.responseTo) &&
        isEmpty(history.messageId) &&
        (stream || storeMessageStream.loading)) ||
      (isUpdateLoading &&
        history.messageId === updatePromptData.messageId &&
        history.responseTo === updatePromptData.responseTo) ||
      (isUpdateLoading && history.responseTo === updatePromptData.messageId)
    );
  };

  const getWelcomeText = () => {
    if (!selectedModal || !selectedModal?.name) {
      return lt("loki_chat_desc");
    } else if (selectedModal?.name == "Loki" || selectedModal?.name == "Normal Chat" || selectedModal?.name == 'groupChat') {
      return lt("loki_chat_desc");
    } else if (selectedModal?.name == "Professor") {
      return lt("professor_chat_desc");
    } else if (selectedModal?.name == "Copywriter") {
      return lt("copywriting_chat_desc");
    } else {
      return lt(selectedModal.key);
    }
  };




  function replaceBracketsContent(text: string) {
    // This regex matches text inside square brackets and any characters right after closing the brackets
    const regex = /\[([^\]]+)\](?:\([^\)]+\))?/g;
    return text.replace(regex, '$1');
  }

  const replyHandler = () => {
    console.log("Button Reply Handler");
    setReply(selectedText);
  };

  const hasValidFileUrl = (messages: { fileUrl: string | null; type?: string }[]): boolean => {
    const result = messages.some((msg) => {
      const hasUrl = msg.fileUrl !== null && msg.fileUrl.trim() !== "";
      const isSummary = msg.type === "SUMMARY";
      return hasUrl || isSummary;
    });
    
    console.log("Checking messages for fileUrl or type: SUMMARY", messages);
    console.log("Has valid fileUrl or SUMMARY type:", result);
    
    return result;
  };
  
   // This effect only runs when `createChat.message` changes
   useEffect(() => {
    console.log("hasFileUrl",hasFileUrl);
    if (createChat?.data?.messages?.length) {
      const hasUrl = hasValidFileUrl(createChat?.data?.messages);
      setHasFileUrl(hasUrl);
    } else {
      setHasFileUrl(false); // If there are no messages, reset to false
    }
  }, [createChat?.data?.messages]);
  
  
  // useEffect(() => {
  //   console.log("dispatch(getVoicesList())");
  //   dispatch(getVoicesList())
  //   // dispatch(resetChatAndNotebooks(null));
    
  // }, []);
  return (
    // !selectedModal && (!fromGuest || fromGuest === 'profiles' || !docProcessing) ?
    //   <ProfileCards fromGuest />
    //   :
      <>
        <Chat
          title={`${pathname !== CHAT_LIST ? lt("hello") : ""} ${pathname === CHAT_LIST
            ? selectedChatListItem.data?.title
            : userData.user.data?.userName ?? ""
            }`}
          setToggle={setToggle}
          toggle={toggle}
          guest={fromGuest}
          docProcessing={docProcessing}
        >
          { (fromGuest === 'profiles' || (isEmpty(createChat.data?.id) && !createChat.loading && !stream)) ? (
            <div>
              {!createChat.loading && (
                <div className={`relative px-[24px] pt-[24px] pb-[120px] bg-n-3/75 dark:bg-n-5/50 rounded-[16px]`}>
                  <span className="block text-[17px] !font-mont">{getWelcomeText()}</span>



                  {selectedModal?.name != "Copywriter" && (
                    <figure className="absolute left-6 -bottom-8">
                      <Image src={LOKI_LOGO} height={64} width={64} alt={"logo"} />
                    </figure>
                  )}
                </div>
              )}
            </div>
          ) : fromGuest !== 'profiles' ? (
            <div id="messageContainerChild">
            <div className=" space-y-10 mb-3 ">
              {createChat?.data?.messages &&
                createChat?.data?.messages?.map((history: any, i: any) => (
                  // <></>
                  <>
                    {history?.content && history?.sender !== ASSISTANT && (
                      <Question
                        selectedMessage={history}
                        setUpdatePromptData={(data: any) =>
                          setUpdatePromptData(data)
                        }
                        key={i + "m"}
                        content={
                          <p
                            className="text-base"
                            dangerouslySetInnerHTML={{ __html: replaceBracketsContent(history?.content) }}
                          />
                        }
                        question
                        time={
                          formatDateRelative(history?.createdAt) || "Just now"
                        }
                        allowEditQuestion={
                          updatePromptData.messageId === history?.messageId
                        }
                        fromGuest={fromGuest}
                      />
                    )}
                    {showAnswerData(history) && (
                      // <></>
                      <Answer
                        time={
                          formatDateRelative(history?.createdAt) || "Just now"
                        }
                        key={i + "r"}
                        contents={history}
                        loading={showAnswerDataLoading(history)}
                        isRegenerate={isRegenerate}
                        handleAddToLiveNotebook={() =>
                          handleAddToLiveNotebook(history, createChat.data?.id)
                        }
                        handleRegenerate={() =>
                          handleRegenerateChat(
                            history,
                            createChat?.data?.messages[i - 1],
                            createChat?.data.id
                          )
                        }
                        fromGuest={fromGuest}
                      >
                        <div className={`mb-4 text-base Answer ${fromGuest && `text-black`}`} onMouseUp={() => handleTextSelect(history._id, i)} >

                          <span dangerouslySetInnerHTML={{
                            __html: showButton === history._id ? markdownToHtml(history.content)?.
                              replace(selectedText, `
                            <span style="background-color: #ACCEF7; position: relative;">
                              <span style="position: absolute; top: -24px; left: -20px;">
                                <button id="quotebtn${i}">
                                  <svg width="43" height="32" viewBox="0 0 43 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_3133_20193)">
                                      <rect x="4" width="35" height="24" rx="12" fill="white"/>
                                      <rect x="4.25" y="0.25" width="34.5" height="23.5" rx="11.75" stroke="#A6A9A9" stroke-width="0.5"/>
                                      <path d="M19.75 12.1974C19.3116 12.4703 18.7912 12.6009 18.2674 12.5697C17.7435 12.5385 17.2446 12.3471 16.8459 12.0244C16.4472 11.7017 16.1703 11.2652 16.057 10.7808C15.9437 10.2963 16.0002 9.79016 16.2179 9.33868C16.4355 8.8872 16.8026 8.51487 17.2637 8.27785C17.7248 8.04083 18.255 7.95198 18.7742 8.0247C19.2934 8.09743 19.7735 8.32778 20.1421 8.68101C20.5107 9.03424 20.7478 9.49119 20.8176 9.98294C21.0384 11.0076 21.1243 12.2398 20.6924 13.3553C20.2291 14.5503 19.202 15.5303 17.3625 15.9855C17.2092 16.0188 17.0482 15.9943 16.9137 15.9173C16.7792 15.8403 16.6817 15.7168 16.6418 15.5729C16.602 15.4291 16.6231 15.2763 16.7004 15.1469C16.7778 15.0174 16.9055 14.9216 17.0564 14.8797C18.5445 14.5114 19.2401 13.7761 19.5564 12.9619C19.6502 12.7189 19.7137 12.4627 19.75 12.1974Z" fill="black"/>
                                      <path d="M25.75 12.1974C25.3116 12.4703 24.7912 12.6009 24.2674 12.5697C23.7435 12.5385 23.2446 12.3471 22.8459 12.0244C22.4472 11.7017 22.1703 11.2652 22.057 10.7808C21.9437 10.2963 22.0002 9.79016 22.2179 9.33868C22.4355 8.8872 22.8026 8.51487 23.2637 8.27785C23.7248 8.04083 24.255 7.95198 24.7742 8.0247C25.2934 8.09743 25.7735 8.32778 26.1421 8.68101C26.5107 9.03424 26.7478 9.49119 26.8176 9.98294C27.0384 11.0076 27.1243 12.2398 26.6924 13.3553C26.2291 14.5503 25.202 15.5303 23.3625 15.9855C23.2092 16.0188 23.0482 15.9943 22.9137 15.9173C22.7792 15.8403 22.6817 15.7168 22.6418 15.5729C22.602 15.4291 22.6231 15.2763 22.7004 15.1469C22.7778 15.0174 22.9055 14.9216 23.0564 14.8797C24.5445 14.5114 25.2401 13.7761 25.5564 12.9619C25.6502 12.7189 25.7137 12.4622 25.75 12.1969" fill="black"/>
                                    </g>
                                    <defs>
                                      <filter id="filter0_d_3133_20193" x="0" y="0" width="43" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="4"/>
                                        <feGaussianBlur stdDeviation="2"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3133_20193"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3133_20193" result="shape"/>
                                      </filter>
                                    </defs>
                                  </svg>
                                </button>
                              </span>
                              <span> ${selectedText} </span>
                            </span>
                          `) : markdownToHtml(history.content)
                          }}></span>
                        </div>
                      </Answer>
                    )}
                  </>
                ))}
              {(createChat.loading == true || storeMessageStream.loading) &&
                size(messageData) > 0 && (
                  <div className="">
                    <Question
                      content={
                        <p
                          className="text-base"
                          dangerouslySetInnerHTML={{ __html: replaceBracketsContent(messageData) }}
                        />
                      }
                      question
                      time={"Just now"}
                      allowEditQuestion={
                        createChat.loading || storeMessageStream.loading
                      }
                    />
                  </div>
                )}
              {createChat.loading == true ? (
                <div className="">
                  <Answer loading />
                </div>
              ) : (
                ""
              )}
            </div>
            </div>
          ): null}
        </Chat>

      <Message
        value={message}
        setMessage={(value: any) => setMessage(value)}
        onChange={(e: any) => setMessage(e.target.value)}
        disabled={
          createChat.loading ||
          stream ||
          updateStream?.loading ||
          storeMessageStream.loading ||
          !hasFileUrl
          // !(
          //   createChat.message?.length &&
          //   hasValidFileUrl(createChat.message) // Use the function to check for valid fileUrl
          // )
          // !(createChat.message?.length && createChat.message.some((msg: any) => msg.fileUrl)) // Disable if message array is empty, undefined, or no message has fileUrl
        }
        setMessageData={setMessageData}
        selectedChatModel={selectedChatModel}
        isRegenerate={isRegenerate}
        reply={reply}
        setReply={setReply}
        fromGuest={fromGuest}
        docProcessing={docProcessing}
      />
      {/* {createChat?.data?.messages?.length !== 0 ? ( */}

      <div className="ml-auto px-10">
        <button
          className={
            createChat?.data?.messages?.length !== 0
              ? "clear-btn"
              : "clear-btn disabled"
          }
          disabled={createChat?.data?.messages?.length === 0}
          onClick={() => {
            dispatch(resetAllChatsData(null));
          }}
        >
          {lt("clear_chat")}
        </button>
      </div>

      {/* ) : null} */}
    </>
  );
};

export default Main;
