import React, { useState } from "react";
import Modal from "../Modal";
import Field from "../Field";
import config from "@/config/index";
import axios from "axios";
import Icon from "../Icon";
import { postBugReport } from "store/actions/superUser.actions";
import { useDispatch } from "react-redux";
import SuccessFullyTranslated from "../SuccessfullyTranslated";
import { useTranslation } from "react-i18next";

const BugReport = () => {
  const [showBugModal, setShowBugModal] = useState<boolean>(false);
  const [bugError, setBugError] = useState<string>("");
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  const {t} = useTranslation("translation")
  const [bugData, setBugData] = useState({
    name: "",
    email: "",
    query: "",
    date: new Date().toISOString(),
    status: "failure",
  });
  const handleInputChange = (e: any) => {
    setBugData({
      ...bugData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      console.log("clicked");
      if (bugData?.query?.length > 200) {
        setBugError(
          "Text exceeds 200 characters. Please limit to 200 or fewer."
        );
      } else {
        setBugError("");
        const response = await dispatch(postBugReport(bugData));
        if (response?.payload?.success) {
          setBugData({
            name: "",
            email: "",
            query: "",
            date: new Date().toISOString(),
            status: "failure",
          });
          setShowBugModal(false);
          setSuccessModal(true);
        }
      }
    } catch (error: any) {
      console.log("error while updaing bug report", error);
      setBugError(error);
    }
  };

  return (
    <>
      <button
        className=""
        onClick={() => {
          setShowBugModal(true);
        }}
      >
        <svg
          width="24"
          height="40"
          viewBox="0 0 24 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 30.0586L4.53 32.6545C4.245 33.0119 3.9186 33.092 3.5508 32.8949C3.183 32.6977 2.9994 32.3448 3 31.8362V14.2573C3 13.6366 3.1764 13.1053 3.5292 12.6637C3.882 12.222 4.3056 12.0008 4.8 12H19.2C19.695 12 20.1189 12.2212 20.4717 12.6637C20.8245 13.1061 21.0006 13.6373 21 14.2573V27.8012C21 28.422 20.8239 28.9536 20.4717 29.396C20.1195 29.8385 19.6956 30.0593 19.2 30.0586H7ZM7 27.8012H19.2V14.2573H4.8V29.071L7 27.8012Z"
            fill="#6C7275"
          />
          <path
            d="M12.713 24.7408C12.521 24.9136 12.2833 25 12 25C11.718 25.0012 11.4807 24.9151 11.288 24.7417C11.0953 24.5683 10.9993 24.3544 11 24.1C11.0007 23.8456 11.0967 23.632 11.288 23.4592C11.4793 23.2864 11.7167 23.2 12 23.2C12.2833 23.2 12.5207 23.2864 12.712 23.4592C12.9033 23.632 12.9993 23.8456 13 24.1C13.0007 24.3544 12.905 24.568 12.713 24.7408Z"
            fill="#6C7275"
          />
          <path
            d="M12.713 21.1408C12.521 21.3136 12.2833 21.4 12 21.4C11.7173 21.4006 11.48 21.3145 11.288 21.1417C11.096 20.9689 11 20.755 11 20.5V16.9C11 16.6462 11.096 16.4326 11.288 16.2592C11.48 16.0858 11.7173 15.9994 12 16C12.2827 16.0006 12.52 16.087 12.712 16.2592C12.904 16.4314 13 16.645 13 16.9V20.5C13.0007 20.7544 12.905 20.968 12.713 21.1408Z"
            fill="#6C7275"
          />
        </svg>
      </button>
      <Modal
        classWrap="max-w-[43rem] p-8"
        classButtonClose="absolute top-3 right-3 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
        visible={successModal}
        onClose={() => {
          //  setcurStep(1); setpayload({ targetLanguage: '', file: '' }); settranslateProgress(0);
          setSuccessModal(false);
        }}
        // nonClosed={true}
      >
        <SuccessFullyTranslated
          nextClick={() => setSuccessModal(false)}
          title={t('successfully_submitted')}
          desc={t('feedback_submitted')}
          btnTitle={t('continue')}
        />
      </Modal>

      <Modal
        visible={showBugModal}
        classButtonClose="hidden"
        onClose={() => {
          setShowBugModal(false);
        }}
        className="w-[100%]"
      >
        <div className="flex flex-col  p-[48px]">
          <div>
            <div className="mb-[20px] flex justify-between align-middle items-center">
              <h1 className="!font-extrabold text-3xl ">{t("report_a_bug")}</h1>
              <div
                onClick={() => setShowBugModal(false)}
                className="cursor-pointer"
              >
                <Icon
                  name={"close"}
                  size={24}
                  className="dark:fill-white fill-n-9 w-6 h-6"
                />
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row justify-between">
                <Field
                  type="text"
                  onChange={handleInputChange}
                  value={bugData.name}
                  placeholder={t("write_your_name_here")}
                  label="Name"
                  className="!font-extrabold w-[48%] "
                  name={t("name")}
                  height="h-10"
                  required
                />
                <Field
                  onChange={handleInputChange}
                  value={bugData.email}
                  placeholder={t("write_your_email_here")}
                  className="!font-extrabold w-[48%]"
                  label={t("email")}
                  height="h-10"
                  name="email"
                  required
                />
              </div>
              <div className="flex flex-col mt-6 gap-2">
                <Field
                  onChange={handleInputChange}
                  value={bugData.query}
                  textarea
                  className="!font-extrabold"
                  label={t("write_down_your_query_here")}
                  placeholder={t("query")}
                  name="query"
                  height="h-40"
                  required
                  showCount={false}
                />
              </div>
              {bugError && (
                <p className="text-red-700 text-sm my-2">{bugError}</p>
              )}
              <button className="btn-blue w-[100%] mt-6">{t("submit")}</button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default BugReport;
