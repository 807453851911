import React, { useEffect, useState } from "react";
import Modal from "@/components/Modal";
import UploadTranscriptionDocument from "../UploadTranscriptionDocument";
import toast from "react-hot-toast";
import axios from "axios";
import Config from "../../config/index";
import { IDocumentTranscriptionState } from "interfaces/dt.interface";
import Notify from "../Notify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getInprogressTranscriptions } from "../../store/actions/transcription.actions";
import { useDispatch } from "react-redux";
import AudioLoader from "@/components/AudioLoader";
import { io } from "socket.io-client";
import { SOCKET_EVENT_TYPES } from "@/constants/notifications";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { setUploadingTranscription } from "store/slices/TranscriptionSlice";
import LoaderWave from "@/components/LoaderWave";
import { SOCKET_OPTIONS, SOCKET_URL } from "@/config/socketConfig";

// const socket = io(Config.backendBaseUrl, {});

const socket = io(SOCKET_URL, SOCKET_OPTIONS);

type TranscriptionModelProps = {
  visible: boolean;
  onClose: () => void;
};

const TranscriptionModel = ({ visible, onClose }: TranscriptionModelProps) => {
  const { t } = useTranslation("translation");

  const dispatch: any = useDispatch();

  const userData = useSelector((state: any) => state.authStore);
  const transcriptionData = useSelector(
    (state: any) => state.transcriptionStore
  );
  const { inProgressTranscriptions, uploadingTranscription } =
    transcriptionData;

  const { user } = userData;

  const [payload, setpayload] = useState<IDocumentTranscriptionState>({
    file: "",
  });
  const [response, setresponse] = useState<any>({});
  const [transcriptionProgress, setTranscriptionProgress] =
    useState<boolean>(false);
  const [curStep, setcurStep] = useState<number>(1);
  const ffmpeg = new FFmpeg();

  const load = async () => {
    if (!ffmpeg.loaded) {
      await ffmpeg.load();
    }
  };

  useEffect(() => {
    dispatch(getInprogressTranscriptions());
  }, [visible]);

  useEffect(() => {
    try {
      if (user && user.data && user.data.id) {
        console.log("socket dispatched");
        socket.on(
          `${SOCKET_EVENT_TYPES.TRANSCRIPTION_GENERATED_NOTIFICATION}`,
          (payload) => {
            if (payload?.userId == user?.data?.id) {
              dispatch(getInprogressTranscriptions());
            }
          }
        );
      }
    } catch (error) {}
  }, [user]);

  const changeStepHandler = (toBack: boolean = false) => {
    if (toBack) {
      if (curStep != 1) {
        setcurStep((ps: number) => ps - 1);
      }
    } else {
      if (curStep != 4) {
        setcurStep((ps: number) => ps + 1);
      }
    }
  };

  const showToast = (msg: any, err?: boolean) => {
    const icon = err == true ? "iconError" : "iconCheck";
    toast(
      (t) => (
        //@ts-ignore
        <Notify className="md:flex-col md:items-center md:px-10" iconError>
          <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">{msg}</div>
        </Notify>
      ),
      { duration: 5000 }
    );
  };

  const getAudioDuration = (audio: HTMLAudioElement): Promise<number> => {
    return new Promise((resolve, reject) => {
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = () => {
        reject(new Error("Failed to load audio metadata."));
      };
    });
  };

  async function compressAudio(file: any) {
    const time = new Date().getTime();
    let compressedFile;
    if (file.size <= 10 * 1024 * 1024) {
      compressedFile = file;
    } else {
      const audio = new Audio();
      audio.src = URL.createObjectURL(file);
      const duration = await getAudioDuration(audio);
      await load();
      const targetSizeInKB = 18 * 1024; // 18 MB target size
      const fileDurationInSeconds = duration; // Make sure this duration is correct.
      const targetBitrate = Math.floor(
        (targetSizeInKB * 8) / fileDurationInSeconds
      ); // Calculate target bitrate in kbps, rounded down

      const b = await file.arrayBuffer();
      const uint8Array = new Uint8Array(b);
      await ffmpeg.writeFile("input.mp3", uint8Array);

      // Compress the file using a constant bitrate that should approximate the desired file size
      await ffmpeg.exec([
        "-i",
        "input.mp3",
        "-ar",
        "22050", // Set audio sample rate to 22050 Hz if it's higher
        "-ac",
        "1",
        "-codec:a",
        "libmp3lame",
        "-b:a",
        `${targetBitrate}k`, // Use a constant bitrate
        "output.mp3",
      ]);

      // Read the resulting file
      const data = await ffmpeg.readFile("output.mp3");
      const audioBlob = new Blob([data], { type: "audio/mp3" });
      compressedFile = new File([audioBlob], file.name, {
        type: file.type,
      });

      // Check the size of the resulting file
      console.log(
        `Compressed size: ${compressedFile.size / 1024 / 1024} MB`,
        "time taken ",
        (new Date().getTime() - time) / 1000
      );
    }

    return compressedFile;
  }
  const initTranslation = async (val: string) => {
    if (!payload.file) {
      toast.error("File is required.");
      return;
    }
    if (payload.file?.size > 600 * 1024 * 1024) {
      showToast("File size should be less than 500mb");
      changeStepHandler(true);
      return;
    }
    if (payload.file?.size < 1024) {
      showToast("File size should be greater than 1kb");
      changeStepHandler(true);
      return;
    }
    try {
      dispatch(setUploadingTranscription(true));
      setTranscriptionProgress(true);
      const formData = new FormData();
      formData.append("userId", userData?.user?.data?.id);
      formData.append("type", val);
      let compressedFile = await compressAudio(payload.file);
      formData.append("file", compressedFile);
      changeStepHandler();
      const res = await axios.post(
        `${Config.baseApiUrl}/transcriptions/generate`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 3000000,
        }
      );
      if (res.status == 200) {
        setTranscriptionProgress(false);
        setresponse(res.data);
        changeStepHandler();
        onClose();
        toast(
          (to) => (
            <Notify iconCheck>
              <div className="h6">{t("transcribing_in_background")}</div>
            </Notify>
          ),
          { duration: 5000 }
        );
      } else {
        toast.error("Failed to transcribe.");
        showToast("Failed to transcribe.");
        changeStepHandler(true);
      }
      dispatch(setUploadingTranscription(false));
      setTranscriptionProgress(false);
    } catch (error: any) {
      dispatch(setUploadingTranscription(false));
      setTranscriptionProgress(false);

      const errorMessages = [];
      if (
        error?.response?.data?.errors &&
        Object.keys(error?.response?.data?.errors)?.length > 0
      ) {
        for (const key in error?.response?.data?.errors) {
          if (error?.response?.data?.errors.hasOwnProperty(key)) {
            errorMessages.push(
              `${error?.response?.data?.errors[key].join(", ")}`
            );
          }
        }
      }
      const errorMessagesString = errorMessages.join(", ");
      console.log({ errorMessagesString });
      if (errorMessagesString && errorMessagesString.length > 0) {
        //@ts-ignore
        toast.error(
          errorMessagesString ??
            error?.response?.data?.message ??
            error?.data?.message ??
            error?.message ??
            "Failed to transcribe due to Unknown reason."
        );
        showToast(
          errorMessagesString ??
            error?.response?.data?.message ??
            error?.data?.message ??
            error?.message ??
            "Failed to transcribe due to Unknown reason."
        );
      } else {
        //@ts-ignore
        toast.error(
          error?.response?.data?.message ??
            error?.data?.message ??
            error?.message ??
            "Failed to transcribe due to Unknown reason."
        );
        showToast(
          error?.response?.data?.message ??
            error?.data?.message ??
            error?.message ??
            "Failed to transcribe due to Unknown reason."
        );
      }

      changeStepHandler(true);
    }
  };

  return (
    <Modal
      classWrap="max-w-[43rem] p-8"
      classButtonClose="absolute top-3 right-3 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
      visible={visible}
      onClose={() => {
        setcurStep(1);
        setpayload({ file: "" });
        setTranscriptionProgress(false);
        onClose();
      }}
      nonClosed={true}
    >
      {inProgressTranscriptions?.inProgressCount > 0 ||
      transcriptionProgress ||
      uploadingTranscription ? (
        <div className="bg-n-2 dark:bg-n-5/50 rounded-2xl  relative flex flex-col justify-center h-full items-center p-5">
          <div className="w-full h-12 flex justify-center items-center mt-5">
            <LoaderWave size={60} />
          </div>
          {/* <AudioLoader /> */}
          <p className="font-bold text-lg">
            {inProgressTranscriptions?.inProgressCount > 0 &&
            !uploadingTranscription
              ? t("transcript_generation_inprogress")
              : t("uploading")}
          </p>
        </div>
      ) : (
        <UploadTranscriptionDocument
          transcriptionProgress={transcriptionProgress}
          nextClick={initTranslation}
          state={payload}
          setState={setpayload}
        />
      )}
    </Modal>
  );
};

export default TranscriptionModel;
