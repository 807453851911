import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { LangSelect, ArrowDown } from "../SVGs"; // Import the LangSelect icon

const LanguagesSearchSelect = ({
  langData,
  selected,
  setSelected,
  placeholder,
}: any) => {
  const { t } = useTranslation("translation");
  const [isOpen, setIsOpen] = useState(false);
  // Custom SingleValue component to display LangSelect icon only in the input bar
  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
       {props.data.label} {/* Display selected option's label */}
      </div>
    </components.SingleValue>
  );

  // Custom Control component to display LangSelect icon always
  const Control = (props: any) => (
    <components.Control {...props}>
      <div className="flex items-center px-[16px] justify-center w-full">
        <div className="flex items-center w-full">

        <LangSelect /> {/* Icon on the left side of the input */}
      {props.children}
        </div>

        <ArrowDown/>

      </div>
    </components.Control>
  );

  const customStyles = {
    placeholder: (provided: any) => ({
      ...provided,
      color: "#444",
      fontWeight:"600",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
  };

  return (
    <div>
      <Select
        placeholder={
          isOpen ? t("search_for_a_language") : placeholder || t("output_language")
        }
        onChange={(newValue) => {
          setSelected(newValue);
        }}
        options={langData?.map((i: any) => ({ value: i.code, label: i.name }))}
        className="selects"
        isClearable
        classNamePrefix="languageSelect-dropdown"
        styles={customStyles}
        components={{ SingleValue, Control }}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default LanguagesSearchSelect;
