import Chat from "@/components/Chat";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
const HowItWorkPage: React.FC = () => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null); // Specify the ref type
  const { t } = useTranslation("translation");

  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setVideoPlaying(true);
    }
  };

  return (
    <>
      {/* <Chat title={t("how_it_work")}> */}
      <div className="how-it-work-section">
        <video
          width="100%"
          height="100%"
          controls={videoPlaying}
          poster="/images/video-poster-new.png"
          style={{ borderRadius: "28px" }}
          ref={videoRef}
        >
          <source src="/images/promo-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {!videoPlaying && (
          <div
            className="video-start-btn cursor-pointer"
            onClick={handleVideoPlay}
          >
            <div className="play-triangle"></div>
          </div>
        )}
      </div>
      {/* </Chat> */}
    </>
  );
};

export default HowItWorkPage;
