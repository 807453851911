import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import { useColorMode } from "@chakra-ui/color-mode";
import { Button } from "../Common/Button";
import { useRouter } from "next/router";
import SelectLanguage from "@/components/Chat/SelectLanguage";
import { useTranslation } from "react-i18next";
import { handleGetStartedClick } from "lib/getStartedUserClick";
import GuestSelect from "@/components/GuestSelect";
import { ROUTES } from "@/constants/routes";
import AvatarGroup from "../../../public/legal-Images/webpImages/AvatarGroup.webp";

export default function Navbar() {
  const router = useRouter();
  const { colorMode } = useColorMode();
  const { t } = useTranslation("translation");

  const [navbar, setNavbar] = useState(false);
  const [hoveredItem, setHoveredItem] = useState("");
  const [language, setLanguage] = useState(0);
  const [maxWidthClass, setMaxWidthClass] = useState("max-w-[1152px]");
  const widgets = [
    {
      id: 0,
      title: t("dropdown_img"),
      value: "photo_generation",
    },
    {
      id: 1,
      title: t("dropdown_spech"),
      value: "text_to_speech",
    },
    {
      id: 2,
      title: t("dropdown_docTrans"),
      value: "doc_translation",
    },
    {
      id: 3,
      title: t("dropdown_doc"),
      value: "chat_with_doc",
    },
    {
      id: 4,
      title: t("dropdown_trans"),
      value: "transcription",
    },
    {
      id: 5,
      title: t("dropdown_model"),
      value: "Loki Profiles",
    },
  ];
  const [widget, setWidget] = useState(widgets[0]);

  const Navigation = [
    {
      name: t("document_translator"),
      href: "/document-translator",
    },
    // {
    //   name: t("affiliate_program"),
    //   href: "/affiliate-program",
    // },
    {
      name: t("document_processing"),
      href:  "/document-processing-home",
    },

    // {
    //   name: t("howIt_works"),
    //   href: "/how-it-works",
    // },
    {
      name: t("pricing"),
      href: "/pricing-home",
    },
    // {
    //   name: t("download"),
    //   href: "/download",
    // },

    {
      name: t("contact"),
      href: "/contact",
    },
  ];

  useEffect(() => {
    if (language == 2 || language == 5 || language == 7 || language == 8) {
      setMaxWidthClass("w-[1280px]");
    } else {
      setMaxWidthClass("w-[1152px]");
    }
  }, [language, t]);

  const handleStartClick = () => {
    handleGetStartedClick(router);
  };

  const onSelectFeature = (item: any) => {
    router.push(ROUTES.FEATURES + "/" + item?.value);
    setHoveredItem("");
  };
  {
    /* <div className="lsm:max-w-[700px] llg:max-w-[850px] l2xl:max-w-[1060px] l3xl:max-w-[1120px] lxl:max-w-[970px] w-full flex justify-between items-center">
            <span>
              <MoodLogoDark />
            </span>
           <span
            className="text-[rgba(248,248,248,0.70)] cursor-pointer"
            onClick={handleStartClick}
          >
            <GetStarttedIcon/>
           
          </span>
          </div> */
  }

  return (
    <header className="Header montserrat  dark:bg-[#202020] sticky top-0 z-50" id="Header">
       <div
        className={`${maxWidthClass} w-full flex justify-between items-center py-[20px] pl-[36px] pr-[36px] plg:pr-[12px] plg:pl-[12px] plg:py-[5px] sm:pr-[18px]  border-b border-n-30 `}
      >
        <nav className="lxl:gap-[40px] llg:flex llg:items-center py-[20px]">
          <div className="lxl:pr-[40px] llg:pr-6 llg:border-r border-gray-50 flex items-center justify-between py-3 llg:block llg:py-2 ">
            <Link href="/">
              <figure>
                <Image
                  src={
                    colorMode === "dark"
                      ? "https://lokichatdev.blob.core.windows.net/images/loki/logo.svg"
                      : "https://lokichatdev.blob.core.windows.net/images/loki/logow.svg"
                  }
                  height={32}
                  width={108}
                  alt="logo"
                />
              </figure>
            </Link>
            <div className="llg:hidden absolute right-5">
              <button
                className="rounded-md p-1.5 text-gray-700 outline-none focus:border focus:border-gray-400"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 dark:text-white text-text-gray-50"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 dark:text-white text-gray-50"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div
            className={`llg:overflow-visible duration-300 ease-linear llg:flex w-full justify-between ${
              navbar
                ? "h-auto pt-2 llg:h-auto llg:pb-0 llg:pt-0"
                : "mt-0 h-0 llg:h-auto overflow-hidden"
            }`}
            onMouseLeave={() => setHoveredItem("")}
          >
            <ul className="llg:items-center llg:justify-center llg:flex-row flex-col flex lxl:gap-[48px] llg:space-x-8 llg:gap-0 gap-6 llg:mb-0 mb-6 plg:pl-[24px] llg:gap[34px]">
              {Navigation &&
                Navigation.map((item, i) => (
                  <li
                    key={i}
                    onMouseEnter={() => setHoveredItem(item.name)}
                    className="relative"
                  >
                    <Link
                      href={item.href === "/features" ? "" : item.href}
                      className={`${
                        item.href === router.pathname
                          ? "dark:text-white text-black"
                          : "text-[rgba(248,248,248,0.7)] hover:text-black dark:hover:text-white"
                      } llg:text-sm text-xs font-semibold font-montserrat`}
                    >
                      {item.name}
                    </Link>
                    {item.name === t("features") &&
                      hoveredItem === t("features") && (
                        <div className="absolute top-full left-0 mt-2 z-10 w-[250px]">
                          <GuestSelect
                            classButton="bg-white rounded-t-lg border-b rounded-b-none  dark:bg-n-5 dark:text-n-6"
                            title={"Select Feature"}
                            items={widgets}
                            value={widget}
                            fromHome={true}
                            onChange={onSelectFeature}
                            arrowColor={"black"}
                          />
                        </div>
                      )}
                  </li>
                ))}
            </ul>
            <div className="flex llg:flex-row flex-col gap-6 llg:items-center items-start llg:invisible">
              <SelectLanguage
                className="llg:!right-0 -right-[231px]"
                setLanguage={setLanguage}
              />
              <button
                onClick={handleStartClick}
                className="cursor-pointer font-montserrat text-xs font-semibold tracking-[-0.14px] flex justify-center items-center gap-3 px-4 py-3 rounded-[90px] border-2 border-[#2A85FF] bg-[#2A85FF]"
              >
                <p className="text-white text-center text-[14px] font-semibold leading-[16px] tracking-[-0.14px]">
                {t("get_started")}

                </p>

                {/* {t("coming_soon")} */}
              </button>
            </div>
          </div>
        </nav>
        <div className="flex llg:flex-row flex-col gap-6 llg:items-center items-start lg:invisible ">
          <SelectLanguage
            className="llg:!right-0 -right-[231px]"
            setLanguage={setLanguage}
          />
          <button
            onClick={handleStartClick}
            className="cursor-pointer font-montserrat text-xs font-semibold tracking-[-0.14px] flex justify-center items-center gap-3 px-4 py-3 rounded-[90px] border-2 border-[#2A85FF] bg-[#2A85FF]"
          >
            <p className="text-white text-center text-[14px] font-semibold leading-[16px] tracking-[-0.14px]">
            {t("get_started")}
            </p>

            {/* {t("coming_soon")} */}
          </button>
        </div>
      </div>
    </header>
  );
}
