import React, { useState, useEffect, useRef } from "react";
//@ts-ignore
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import Logo from "@/components/Logo";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";
import Search from "@/components/Search";
import Settings from "@/components/Settings";
import Navigation from "./Navigation";
import DocumentList from "./DocumentList";
import Profile from "./Profile";
import ToggleTheme from "./ToggleTheme";
import HowItWorkPAge from "@/templates/HowItWorkPage";

import { translate } from "@/constants/settings";
import { translate as translateNavigation } from "@/constants/navigation";

import { twMerge } from "tailwind-merge";
import { useDispatch, useSelector } from "react-redux";
import { resetAudio, resetCreateAudioData } from "store/slices/AudioSlice";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import {
  resetChatAndNotebooks,
  resetUpdateChatListId,
  setAbortChatModal,
  setAbortNavigationData,
  setChatListModal,
  setMoveChatListData,
  setMoveChatModal,
  setSearchModal,
  setSelectedChatListItem,
  setSelectedNotebooks,
  updateChatListOfChat,
  updateChatListOfModalChats,
} from "store/slices/ChatGptSlice";
import { getStoredChatList } from "store/actions/chatList.actions";
import { isEmpty, size } from "lodash";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../Modal/ConfirmationModal";
import { updateChatListId } from "store/actions/chat.actions";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import AbortChatModal from "../Modal/AbortChatModal";
import { getAllDevices } from "store/actions/device.actions";
import { getPlan } from "store/actions/plan.action";
import CustomSidebarPlayer from "@/components/SidebarAudioPlayer/CustomSidebarPlayer";
import {
  CopyWriterIcon,
  SpeechToText,
  TranslatorIcon,
  PromptAdviserIcon,
  ConsumptionsIcon,
  DocumentProcessingSvg,
  DocumentTranslatingSvg,
  ProcessSvg,
} from "../SVGs";
import { DocumentTranslation } from "../LandingPage/Icons";
import { resetAllAdvisorStates } from "store/slices/AdvisorSlice";
import PromptAdvisorModal from "../Modal/PromptAdvisorModal";
import Menu from "@/components/Menu";
import InstantTranslatorModal from "../Modal/InstantTranslatorModal";
import { CHAT_MODEL } from "@/constants/chats";
import {
  getStoredDocumentList,
  updateDocumentListId,
} from "store/actions/documentList.actions";
import {
  setDocumentListModal,
  setMoveDocumentListData,
  setMoveDocumentModal,
  resetUpdateDocumentListId,
} from "store/slices/TranslationSlice";

type LeftSidebarProps = {
  value: boolean;
  setValue?: any;
  smallSidebar?: boolean;
  visibleRightSidebar?: boolean;
  visibleGoalSideBar?: any;
};

const LeftSidebar = ({
  value,
  setValue,
  smallSidebar,
  visibleRightSidebar,
  visibleGoalSideBar,
}: LeftSidebarProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation("translation");

  //for settings array's localization
  const settings = translate(lt);

  const chatData = useSelector((state: any) => state.chatGptStore);
  const documentData = useSelector((state: any) => state.translationStore);
  const audioStore = useSelector((state: any) => state.audioStore);
  const debateData = useSelector((state: any) => state.debateStore);
  const { response, translateProgress, payloadData } = useSelector(
    (state: any) => state.translationStore.translations
  );
  const { t } = useTranslation("translation");

  const navigationLan = translateNavigation(t);

  const { pathname, query } = router;
  const { updateDebate, updateDebateMessage, updateDebateBotMessage } =
    debateData;

  const {
    modalStates,
    moveChatListData,
    createChat,
    stream,
    streamText,
    updateStream,
  } = chatData;
  const { modalStatesDocument, moveDocumentListData, createDocument } =
    documentData;

  // console.log("moveDocumentListData",moveDocumentListData);

  const { playingAudio, audioItem, isPlaying } = audioStore;

  const [visibleSettings, setVisibleSettings] = useState<boolean>(false);
  const [howItWork, setHowItWork] = useState<boolean>(false);
  const initialReload = useRef<any>(null);
  const [visibleModal, setVisibleModal] = useState<any>(false);

  useEffect(() => {
    if (!initialReload.current) {
      initialReload.current = true;
      dispatch(getStoredDocumentList());
      dispatch(getStoredChatList());
      dispatch(getAllDevices());
      dispatch(getPlan());
    }
    window.addEventListener("keydown", handleWindowKeyDown);
    return () => {
      window.removeEventListener("keydown", handleWindowKeyDown);
      dispatch(setSearchModal(false));
    };
  }, []);

  useEffect(() => {
    if (
      !isEmpty(documentData.updateDocumentListId?.data) &&
      !documentData.updateDocumentListId.loading
    ) {
      toast(
        (t) => (
          <Notify className="md:flex-col md:items-center md:px-10" iconCheck>
            <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
              {lt("document_moved_successfully")}
            </div>
          </Notify>
        ),
        { duration: 1400 }
      );

      setTimeout(() => {
        dispatch(resetUpdateDocumentListId(null));
        dispatch(setMoveDocumentModal(false));
      }, 1000);
    }
    if (
      !isEmpty(documentData.updateDocumentListId?.error) &&
      !documentData.updateDocumentListId.loading
    ) {
      toast(
        (t) => (
          <Notify className="md:flex-col md:items-center md:px-10" iconError>
            <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
              {documentData.updateDocumentListId.error}
            </div>
          </Notify>
        ),
        { duration: 2000 }
      );

      setTimeout(() => {
        dispatch(resetUpdateDocumentListId(null));
        dispatch(setMoveDocumentModal(false));
      }, 1000);
    }
  }, [documentData.updateDocumentListId]);

  const onModalClose = () => {
    if (visibleModal) {
      setVisibleModal(false);
      dispatch(resetAllAdvisorStates({}));
    }
  };

  const handleWindowKeyDown = (event: any) => {
    if (event.metaKey && event.key === "f") {
      event.preventDefault();
      dispatch(setSearchModal(true));
    }
  };

  const getAudioUrl = () => {
    return localStorage.getItem("audioFilePath");
  };

  const getAudioData = () => {
    const audioItem = localStorage.getItem("audioItem");
    if (!isEmpty(audioItem)) {
      return JSON.parse(audioItem ?? "{}");
    } else return {};
  };

  const navigation = [
    // {
    //   title: lt('chats'),
    //   icon: "chat",
    //   color: "fill-accent-2",
    //   url: ROUTES.CHAT,
    //   onClick: () => {
    //     isChatOrDebateLoading(chatPageHandler, ROUTES.CHAT);
    //   }
    // },
    // {
    //   title: lt('chat_search'),
    //   icon: "search",
    //   color: "fill-primary-2",
    //   onClick: () => {
    //     isChatOrDebateLoading(chatSearchClick, null);
    //   }
    // },
    {
      title: t("document_translator"),
      IconComponent: () => <DocumentTranslatingSvg />,
      color: "fill-accent-4",
      onClick: () => {
        isChatOrDebateLoading(null, ROUTES.DOCUMENT_TRANSLATING);
      },
      url: ROUTES.DOCUMENT_TRANSLATING,
    },
    {
      title: t("document_processing"),
      IconComponent: () => <DocumentProcessingSvg />,
      color: "fill-accent-4",
      onClick: () => {
        isChatOrDebateLoading(null, ROUTES.DOCUMENT_PROCESSING);
      },
      url: ROUTES.DOCUMENT_PROCESSING,
    },

    // {
    //   title: lt('text_to_speech'),
    //   IconComponent: () => <SpeechToText />,
    //   color: "fill-accent-4",
    //   onClick: () => {
    //     isChatOrDebateLoading(null, ROUTES.TEXT_SPEECH);
    //   },
    //   url: ROUTES.TEXT_SPEECH,
    // },
    // {
    //   title: lt('audio_library'),
    //   icon: "audio",
    //   color: "fill-accent-5",
    //   onClick: () => {
    //     isChatOrDebateLoading(audioLibraryOnClick, ROUTES.AUDIOLIBRARY);
    //   },
    //   url: ROUTES.AUDIOLIBRARY
    // },
    // {
    //   title: lt("photo_generation").toString(),
    //   icon: "image",
    //   color: "fill-primary-3",
    //   onClick: () => {
    //     isChatOrDebateLoading(null, ROUTES.PHOTO_GENERATION);
    //   },
    //   url: ROUTES.PHOTO_GENERATION,
    // },
    // {
    //   title: lt('image_library'),
    //   icon: "image",
    //   color: "fill-accent-5",
    //   onClick: () => {
    //     isChatOrDebateLoading(null, ROUTES.IMAGE_LIBRARY);
    //   },
    //   url: ROUTES.IMAGE_LIBRARY,
    // },
    // {
    //   title: lt("transcription"),
    //   IconComponent: () => <DocumentTranslation fill={"#90EE18"} />,
    //   color: "fill-accent-5",
    //   onClick: () => {
    //     isChatOrDebateLoading(null, ROUTES.TRANSCRIPTION);
    //   },
    //   url: ROUTES.TRANSCRIPTION,
    // },
    // {
    //   title: lt('trans_library'),
    //   icon: "trans_library",
    //   color: "fill-[#D197FF]",
    //   onClick: () => {
    //     isChatOrDebateLoading(null, ROUTES.TRANS_LIBRARY);
    //   },
    //   url: ROUTES.TRANS_LIBRARY
    // },
    // {
    //   title: lt('referral_management'),
    //   icon: "users",
    //   color: "fill-primary-3",
    //   onClick: () => {
    //     isChatOrDebateLoading(null, ROUTES.REFERRAL_MANAGEMENT);
    //   },
    //   url: ROUTES.REFERRAL_MANAGEMENT,
    // },
    // {
    //   title: lt('goal_management'),
    //   icon: "goal",
    //   color: "fill-accent-4",
    //   onClick: () => {
    //     isChatOrDebateLoading(null, ROUTES.GOAL_MANAGEMENT);
    //   },
    //   url: ROUTES.GOAL_MANAGEMENT,
    // },
    {
      title: lt("manage_subscription"),
      icon: "card",
      color: "fill-accent-4",
      onClick: () => {
        isChatOrDebateLoading(null, ROUTES.PRICING);
      },
      url: ROUTES.PRICING,
    },
    // {
    //   title: lt('update_faq'),
    //   icon: "barcode",
    //   color: "fill-accent-1",
    //   onClick: () => {
    //     isChatOrDebateLoading(null, ROUTES.UPDATESANDFAQS);
    //   },
    //   url: ROUTES.UPDATESANDFAQS,
    // },
    {
      title: lt("setting"),
      icon: "settings",
      color: "fill-accent-3",
      onClick: () => {
        isChatOrDebateLoading(settingsClick, null);
      },
    },
    // {
    //   title: t("how_it_work"),
    //   IconComponent: () => <ProcessSvg color={"#FFC400"} />,
    //   color: "fill-accent-4",
    //   onClick: () => {
    //     //  isChatOrDebateLoading(null, ROUTES.HOW_IT_WORK);
    //     isChatOrDebateLoading(settingsIsClick, null);
    //   },
    //   //url: ROUTES.HOW_IT_WORK,
    // },
    // {
    //   title: lt('update_faq'),

    //   IconComponent: () => <CopyWriterIcon/>,
    //   color: "fill-accent-1",
    //   onlyMobile : true,
    //   onClick: () => {
    //     setVisibleModal('copywriter')
    //     //  setVisibleModal('copywriter')
    //   },
    //   url: ROUTES.UPDATESANDFAQS,
    // },
    // {
    //   title: lt('update_faq'),
    //   IconComponent: () => <TranslatorIcon/>,
    //   color: "fill-accent-1",
    //   onlyMobile : true,
    //   onClick: () => {
    //     setVisibleModal('translator')
    //     //  setVisibleModal('copywriter')
    //   },
    //   url: ROUTES.UPDATESANDFAQS,
    // },
  ];

  const chatPageHandler = () => {
    if (pathname === ROUTES.DEBATE_ID) {
      dispatch(resetChatAndNotebooks(null));
      dispatch(resetCreateAudioData(null));
      dispatch(setSelectedNotebooks([]));
    }
  };

  const settingsClick = () => {
    setVisibleSettings(true);
  };

  const settingsIsClick = () => {
    setHowItWork(true);
  };

  const chatSearchClick = () => {
    dispatch(setSearchModal(true));
  };

  const audioLibraryOnClick = () => {
    dispatch(resetAudio());
    router.push(ROUTES.AUDIOLIBRARY);
  };

  const isChatOrDebateLoading = (clickHandler: any, url: any) => {
    try {
      if (stream && size(streamText) >= 0) {
        const data = {
          clickHandler: clickHandler ?? null,
          url: url ?? null,
        };
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateStream.loading && size(updateStream.text) >= 0) {
        const data = {
          clickHandler: clickHandler ?? null,
          url: url ?? null,
        };
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (
        updateDebate.loading ||
        updateDebateMessage.loading ||
        updateDebateBotMessage.loading
      ) {
        const data = {
          clickHandler: clickHandler ?? null,
          url: url ?? null,
        };
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else {
        if (clickHandler) {
          clickHandler();
        }
        if (url) {
          router.push(url);
        }
      }
    } catch (error) {}
  };

  const handleClick = () => {
    setValue(!value);
    smallSidebar && value ? disablePageScroll() : enablePageScroll();
  };
  const closeMoveDocumentModal = () => {
    if (documentData.updateDocumentListId?.loading) return;
    dispatch(setMoveDocumentModal(false));
    dispatch(setMoveDocumentListData(null));
  };
  const closeMoveChatModal = () => {
    if (chatData.updateChatListId?.loading) return;
    dispatch(setMoveChatModal(false));
    dispatch(setMoveChatListData(null));
  };

  const closeSearchModal = () => {
    if (modalStates.search && modalStates.moveChatConfirmation) return;
    else dispatch(setSearchModal(false));
  };

  const handleDocumentMoveModal = () => {
    try {
      if (!isEmpty(moveDocumentListData.data)) {
        const { documentId, documentListId } = moveDocumentListData.data;
        const data = { documentId, documentListId };
        dispatch(updateDocumentListId(data))
          .unwrap()
          .then(() => {
            if (
              !isEmpty(response.data?.id) &&
              documentId === response.data?.id
            ) {
              setDocumentListModal(false);
            }
            // if (modalStates.search) {
            //   const data = { chatId: chatId, chatList: chatListId }
            //   dispatch(updateChatListOfModalChats(data));
            // }
            dispatch(getStoredDocumentList());
            // if (pathname === ROUTES.CHAT || pathname === ROUTES.CHAT_ID || pathname === ROUTES.CHAT_LIST_ID || pathname === ROUTES.CHAT_LIST) {
            //   dispatch(setSelectedChatListItem(moveChatListData?.data?.chatlist));
            //   const { id } = query;
            //   if(pathname === ROUTES.CHAT_LIST_ID && documentId == id) {
            //     return;
            //   } else {
            //     dispatch(setDocumentListModal(false));
            //     dispatch(resetChatAndNotebooks(null));
            //     dispatch(resetCreateAudioData(null));
            //     dispatch(setSelectedNotebooks([]));
            //     router.push(`/${ROUTES.CHAT_LIST}/${chatId}`);
            //   }
            // }
          });
      }
    } catch (error) {}
  };
  const isAllowedToMoveDocument = () => {
    if (documentData.updateDocumentListId?.loading) return true;
    else return false;
  };
  const isAllowedToMoveChat = () => {
    if (chatData.updateChatListId?.loading) return true;
    else return false;
  };
  const menu = [
    {
      title: lt("update_faq"),
      IconComponent: () => <CopyWriterIcon />,
      color: "fill-accent-1",
      onlyMobile: true,
      onClick: () => {
        setVisibleModal("copywriter");
      },
      url: ROUTES.UPDATESANDFAQS,
    },
    {
      title: lt("update_faq"),
      IconComponent: () => <TranslatorIcon />,
      color: "fill-accent-1",
      onlyMobile: true,
      onClick: () => {
        setVisibleModal("translator");
      },
      url: ROUTES.UPDATESANDFAQS,
    },
    {
      title: lt("update_faq"),
      IconComponent: () => <ConsumptionsIcon />,
      color: "fill-accent-1",
      onlyMobile: true,
      //  onClick: {onClickHandler},
      onClick: () => {
        isChatOrDebateLoading(null, ROUTES.TRACKER);
      },
      url: ROUTES.UPDATESANDFAQS,
    },
    {
      title: lt("update_faq"),
      IconComponent: () => <PromptAdviserIcon />,
      color: "fill-accent-1",
      onlyMobile: true,
      onClick: () => {
        setVisibleModal("prompt");
      },
      url: ROUTES.UPDATESANDFAQS,
    },
  ];
  return (
    <>
      <div
        className={twMerge(
          `fixed z-50 top-0 left-0 bottom-0 flex flex-col pt-30 px-4 bg-n-7 duration-500 over
          ${value ? "w-24 pb-38 md:w-16 md:px-0 md:pb-30" : "w-80 pb-58 "}${
            (visibleRightSidebar || visibleGoalSideBar) &&
            "md:visible md:opacity-100"
          }`
        )}
      >
        <div
          className={`absolute top-0 right-0 left-0 flex items-center h-30 pl-7 pr-6 ${
            value ? "justify-center md:px-4" : "justify-between"
          }`}
        >
          {!value && <Logo />}
          <button className="group tap-highlight-color" onClick={handleClick}>
            <Icon
              className="fill-n-4 transition-colors group-hover:fill-n-3"
              name={value ? "toggle-on" : "toggle-off"}
            />
          </button>
        </div>
        <div className="grow overflow-y-auto overflow-hidden scroll-smooth scrollbar-none">
          <Navigation
            visible={value}
            items={navigation}
            settingsIsClick={settingsIsClick}
          />
          <div
            className={`my-4 h-0.25 bg-n-6 ${
              value ? "-mx-4 md:mx-0" : "-mx-2 md:mx-0"
            }`}
          />
          <DocumentList visible={value} />
          <div className={value ? "block" : "hidden"}>
            {/* <Navigation visible={true} items={menu} /> */}
          </div>
        </div>
        {audioItem && (
          <div className="mb-12">
            <CustomSidebarPlayer
              visible={value}
              audioUrl={audioItem.url}
              isPlaying={isPlaying}
              audioItem={audioItem}
            />
          </div>
        )}
        <div className="absolute left-0 bottom-0 right-0 pb-6 px-4 bg-n-7 before:absolute before:left-0 before:right-0 before:bottom-full before:h-10 before:bg-gradient-to-t before:from-[#131617] before:to-[rgba(19,22,23,0)] before:pointer-events-none md:px-3">
          {/* <Profile visible={value} /> */}
          <ToggleTheme visible={value} />
        </div>
      </div>
      <Modal
        className="md:!p-0 !overflow-hidden"
        classWrap="md:min-h-screen-ios !overflow-hidden md:rounded-none dark:shadow-[inset_0_0_0_0.0625rem_#232627,0_2rem_4rem_-1rem_rgba(0,0,0,0.33)] dark:md:shadow-none"
        classButtonClose="hidden md:flex md:absolute md:top-6 md:left-6 dark:fill-n-1"
        classOverlay="md:bg-n-1"
        visible={modalStates?.search}
        onClose={closeSearchModal}
      >
        <Search />
      </Modal>
      <Modal
        className="md:!p-0"
        classWrap="max-w-[48rem] h-[745px] md:rounded-none"
        classButtonClose="hidden md:block md:absolute md:top-5 md:right-5 dark:fill-n-4"
        classOverlay="md:bg-n-1"
        visible={visibleSettings}
        onClose={() => setVisibleSettings(false)}
      >
        <Settings visibleSettings={visibleSettings} items={settings} />
      </Modal>
      <Modal
        className="md:!p-0"
        classWrap="max-w-[80rem] max-h-[745px] md:rounded-none"
        classButtonClose="hidden md:block md:absolute md:top-5 md:right-5 dark:fill-n-4"
        classOverlay="md:bg-n-1"
        visible={howItWork}
        onClose={() => setHowItWork(false)}
      >
        <HowItWorkPAge />
      </Modal>
      <Modal
        className=""
        classWrap="max-w-[654px]  md:min-h-screen-ios py-16"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={modalStates.abortChat}
        onClose={() => dispatch(setAbortChatModal(false))}
      >
        <AbortChatModal onCancel={() => dispatch(setAbortChatModal(false))} />
      </Modal>
      <Modal
        className="!p-2  py-4 !shadow-2xl"
        classWrap="max-w-[26rem] py-4 !h-auto !top-[40%] !rounded-lg !shadow-2xl !bg-black"
        classButtonClose="hidden md:absolute md:top-5 md:right-5 dark:fill-n-4"
        classOverlay="!bg-transparent !shadow-2xl"
        visible={modalStatesDocument?.moveDocumentConfirmation || false} // Safeguard against undefined
        onClose={closeMoveDocumentModal}
      >
        <ConfirmationModal
          disabled={isAllowedToMoveDocument()}
          onClose={closeMoveDocumentModal}
          onSave={handleDocumentMoveModal}
        />
      </Modal>
      <Modal
        visible={visibleModal ? true : false}
        onClose={onModalClose}
        noicon
        classWrap={
          visibleModal === "translator" || visibleModal === "copywriter"
            ? "max-w-[64rem] h-[75%]"
            : ""
        }
      >
        {visibleModal === "prompt" ? (
          <PromptAdvisorModal onClose={onModalClose} />
        ) : visibleModal === "copywriter" ? (
          <div className="relative px-8 pt-8 pb-10 bg-n-3/75 dark:bg-n-5/50 rounded-3xl h-full ">
            <div className=" mb-3">
              <div className=" w-full flex justify-between">
                <span className="!font-mont h5">{t("enhance_copywriter")}</span>
                <button onClick={onModalClose}>
                  <Icon name={"close"} className=" fill-n-4 d " />
                </button>
              </div>
              <span className="block !font-mont text-[15px] sm:text-[9px] mt-[1%] mb-[2%] font-light">
                {t("copywriting_chat_desc_short")}
              </span>
            </div>
            <Menu
              className="flex items-start gap-2 flex-wrap"
              items={navigationLan}
              selectedChatModel={{
                name: "Copywriter",
                key: CHAT_MODEL.COPY_WRITER,
                icon: <></>,
                current: false,
              }}
              onClose={onModalClose}
            />
          </div>
        ) : visibleModal === "translator" ? (
          <InstantTranslatorModal onClose={onModalClose} />
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default LeftSidebar;
