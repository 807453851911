import React, { useCallback, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import ModalShareChat from "@/components/ModalShareChat";
import { useSelector } from "react-redux";
import { getPlainTextFromHTML } from "lib/getPlainText";
import Icon from "@/components/Icon";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import { useTranslation } from "react-i18next";
import { MESSAGE_SENDER } from "@/constants/chats";

type ActionsProps = {
  addToLiveNotebook?: any;
  handleRegenerate?: any;
  contents?: any;
  fromGuest?: string;
};

const { ASSISTANT } = MESSAGE_SENDER;

const Actions = ({
  addToLiveNotebook,
  handleRegenerate,
  contents,
  fromGuest
}: ActionsProps) => {
  const router = useRouter();
  const { t:lt }= useTranslation('translation');
  const { t }= useTranslation('translation');

  
  const debateData = useSelector((state: any) => state.debateStore);
  const userData = useSelector((state: any) => state.authStore);
  const chatData = useSelector((state: any) => state.chatGptStore);
  
  const { pathname } = router;
  const {
    storeDebate,
    updateDebate,
    debateInvitedUsers,
    updateDebateMessage,
    updateDebateBotMessage,
  } = debateData;
  const { addToLiveNotebook: addToLiveNotebookData, allLiveNotebooks, stream, updateStream, createChat } = chatData;
  const { user } = userData;

  const isDebateUser = debateInvitedUsers?.find((el: any) => el?._id === user?.data?.id);
  const isDebateAdmin = storeDebate.data?.user?._id === user?.data?.id;
  const isDebateArchived = storeDebate.data?.isArchived;

  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [copyButtonDisabled, setCopyButtonDisabled] = useState(false);

  const onCopy = (text?: string, result?: boolean) => {
    if (copyButtonDisabled) {
      return; // Don't do anything if the copy button is disabled
    }
    setCopyButtonDisabled(true);
    setTimeout(() => {
      toast(
        (t) => (
          <Notify iconCheck>
            <div className="h6 sm:text-sm">{lt('ans_copied')}</div>
          </Notify>
        ),
        { duration: 1000 }
      );
      setCopyButtonDisabled(false); // Re-enable the copy button after showing the toast
    }, 1000);
  };

  const isAllowedToAddnotebook = useCallback(() => {
    const { loading, data } = addToLiveNotebookData;

    if(pathname === ROUTES.DEBATE_ID) {
      const addedResponseInNotebook = allLiveNotebooks?.data?.find(
        (notebook: any) => notebook?.messageId === contents?.id
      );
      return (
        !isEmpty(addedResponseInNotebook) ||
        (loading && data?.messageId === contents.id) ||
        (stream || updateStream?.loading) || 
        ((!isDebateAdmin && !isDebateUser) || isDebateArchived)
      );
    } else {
      const addedResponseInNotebook = allLiveNotebooks?.data?.find(
        (notebook: any) => notebook?.messageId === contents?.messageId
      );
      return (
        !isEmpty(addedResponseInNotebook) ||
        (loading && data?.messageId === contents?.messageId) ||
        (stream || updateStream?.loading)
      );
    }
  }, [allLiveNotebooks, addToLiveNotebookData, storeDebate, debateInvitedUsers, stream, updateStream?.loading]);

  const isGoalAssistantMsg = () => {
    return createChat.data?.messages[0]?.isGoalResponse && contents?.messageId === createChat.data?.messages[0]?.messageId;
  }
  const isChatStreamLoading = () => {
    return stream || updateStream.loading;
  }

  const displayRegenerate = () => {
    try {
      if (pathname === ROUTES.DEBATE_ID) {
        if (((contents?.isBotResponse && user?.data?.id === contents?.sender?._id && (isDebateAdmin || isDebateUser || !isDebateArchived)))) {
          return true;
        } else return false;
      } else if (isChatStreamLoading() || contents?.msgSender === ASSISTANT || isGoalAssistantMsg()) return false;
      else return true;
    }
    catch(e) {
      return false;
    }
    
  };

  const returnCopyContent = useCallback(() => {
    try{
      if (pathname === ROUTES.DEBATE_ID) {
        return getPlainTextFromHTML(contents?.message ?? contents?.content ?? "") ?? "Content";
      } else {
          if(contents.response || contents?.content) {
            return getPlainTextFromHTML(contents?.response ?? contents?.content ?? "") ?? "Content";
          } else return "Content"
      }
    }
    catch(e){
      return "Content";
    }
    
  }, [contents]);

  const styleButton: string = `h-6 sm:h-4 px-2 sm:px-1.5 bg-n-3 rounded-md caption1 txt-n-6 transition-colors hover:text-primary-1 ${!fromGuest && 'dark:bg-n-7'}`;

  const isAllowedToRegenerate = () => {
    try {
      if(pathname === ROUTES.DEBATE_ID) {
        return updateDebateMessage.loading || updateDebateBotMessage.loading || updateDebate.loading || storeDebate.loading || (!isDebateAdmin && !isDebateUser) || isDebateArchived;
      } else if((updateStream && updateStream.loading) || stream) {
        return true;
      } else return false;
    } catch (error) {
      
    }
    
  }

  const displayCopyButton = () => {
    try {
      if(pathname === ROUTES.DEBATE_ID && (!isDebateAdmin && !isDebateUser) || isDebateArchived) {
        return false;
      } else return true;
    } catch (error) {
      return true
    }
    
  }

  return (
    <>
      {contents && displayCopyButton() && (
        <CopyToClipboard text={returnCopyContent()} onCopy={onCopy}>
          <button className={`${styleButton} flex items-center gap-1 group`}>
            <Icon
              name={t("copy")}
              className={`w-3 h-3 ${!fromGuest && 'dark:fill-white'} group-hover:hover:dark:fill-primary-1 group-hover:fill-primary-1`}
            />
            <span className={`2xl:hidden !font-mont ${fromGuest && `text-n-4`}`}>{lt("copy")}</span>
          </button>
        </CopyToClipboard>
      )}
      {/* <button
        style={{ display: isAllowedToAddnotebook() ? "none" : "" }}
        onClick={addToLiveNotebook}
        className={`${styleButton} flex items-center gap-1 group`}
      >
        <Icon
          name={"live"}
          className={`w-3 h-3 ${!fromGuest && 'dark:fill-white'} group-hover:hover:dark:fill-primary-1 group-hover:fill-primary-1`}
        />
        <span className={`2xl:hidden !font-mont ${fromGuest && `text-n-4`}`}>{lt('add_speech_creator')}</span>
      </button> */}
      {(contents?.type != 'SUMMARY') && !(contents?.message?.includes('Summary for')) && <button
        disabled={isAllowedToRegenerate()}
        style={{ display: displayRegenerate() ? "" : "none" }}
        onClick={handleRegenerate}
        className={`${styleButton} flex items-center gap-1 group`}
      >
        <span>
          <svg
            width="14"
            height="12"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`group-hover:hover:dark:text-primary-1 group-hover:text-primary-1 text-black ${!fromGuest && 'dark:text-white'}`}
          >
            <path
              d="M3.97935 7.61328L2.81935 6.45329L1.66602 7.61328M14.0194 8.38672L15.1794 9.54671L16.3394 8.38672M15.1727 9.54667V8C15.1727 4.58667 12.406 1.82666 8.99936 1.82666C7.0527 1.82666 5.3127 2.73335 4.17936 4.14001M2.82601 6.45329V7.99996C2.82601 11.4133 5.59268 14.1733 8.99934 14.1733C10.946 14.1733 12.686 13.2666 13.8193 11.8599"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="2xl:hidden !font-mont">{lt('regen_resp')}</span>
      </button>}
      <ModalShareChat
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      />
    </>
  );
};

export default Actions;
