import { USER_LOCAL } from "@/constants/user";
import { loadDecryptedValue } from "./encryption";
import { ROUTES } from "@/constants/routes";
import { isEmpty } from "lodash";

export const handleGetStartedClick = (router: any) => {
  const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
  if (!isEmpty(sessions?.token)) {
    router.push(ROUTES.CHAT);
  } else {
    router.push(ROUTES.SIGNIN);
  }
};

export const handleNavigateStartedClick = (router: any) => {
  const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
  if (!isEmpty(sessions?.token)) {
    router.push(ROUTES.PRICING);
  } else {
    router.push(ROUTES.SIGNIN);
  }
};
