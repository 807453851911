import React from "react";
import Image from "@/components/Image";
import { formatDateRelative } from "lib/DateTime";
import { GOAL_REMINDER } from "@/constants/goals";
import { PROFILE_URL } from "@/constants/user";
import { useTranslation } from "react-i18next";

type NotificationProps = {
  item: any;
  onClick: any;
  close: any;
  handleStartChat: any;
};

const Notification = ({ item, onClick, close, handleStartChat }: NotificationProps) => {
  const { t } = useTranslation('translation');

  return (
    <div
      onClick={() => onClick(item?._id)}
      className="flex items-start p-4 rounded-xl transition-colors hover:bg-n-2 md:hover:bg-transparent md:p-0 dark:hover:bg-n-6 dark:md:hover:bg-transparent"
    >
      <div className="relative shrink-0 w-15 h-15 md:w-12 md:h-12">
        <Image
          className="rounded-full object-cover"
          src={item.profileUrl ?? item?.from?.profileUrl ?? PROFILE_URL}
          fill
          alt="Avatar"
        />
        {item?.online && (
          <div className="absolute right-0 bottom-0 w-4.5 h-4.5 rounded-full border-4 border-n-1 bg-primary-2 dark:border-n-7"></div>
        )}
      </div>
      <div className="w-[calc(100%-3.75rem)] px-5 md:px-3">
      <div><b>{item?.title}</b></div>
        <div className="mb-1 line-clamp-3 base1 text-n-7 md:mb-0 md:base2 dark:text-n-1">
          { item?.name == 'AUDIO_SHARE' ? <><b>{item?.from?.userName ?? item?.from?.email}</b> has shared audio with you.</> : item?.message}
        </div>
        {item?.title === GOAL_REMINDER && (
          <button
            className="btn-stroke-light mr-3"
            onClick={() => {handleStartChat(item);close()}}
          >
            {t('start_aChat')}
          </button>
        )}
        <div>
          <span className="caption1 !font-mont text-n-4">
            {formatDateRelative (item?.createdAt)}
          </span>
        </div>
      </div>
      {item?.new && (
        <div className=" shrink-0 w-4 h-4 rounded-full bg-accent-1 md:w-3 md:h-3"></div>
      )}
      {item?.isRead != true && (
        <div className=" shrink-0 w-4 h-4 rounded-full bg-accent-1 md:w-3 md:h-3"></div>
      )}
    </div>
  );
};

export default Notification;
