import React, { useState, useEffect } from 'react'
import { UploadIcons } from '../../LandingPage/Icons'
import { IDocumentTranslatorState } from 'interfaces/dt.interface';
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from 'react-i18next';
import Select from '@/components/Select';
import { AudioTimerIcon } from '@/components/SVGs';
import { AudioMinutes } from '@/constants/audio';
import { pdfjs } from 'react-pdf';
import { setDetectedLanguage } from 'store/slices/AudioSummarySlice';
import { useDispatch } from 'react-redux';
const LanguageDetect = require('languagedetect');

const lngDetector = new LanguageDetect();

// Set worker source for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
    nextClick: Function;
    state: IDocumentTranslatorState;
    setState: Function;
    description?: string;
    minutes: any;
    setMinutes: any;
    loading: boolean;
}
const UploadDocument = ({ nextClick, state, setState, description, minutes, setMinutes, loading }: IProps) => {
    const { t } = useTranslation('translation');

    const fileTypes = ["pdf", "application/pdf"];
    const [file, setFile] = useState<any>(null);
    const dispatch = useDispatch();
    useEffect(() => {
        setState({ ...state, file: file })
    }, [file])

    const detectSingleLanguage = (text: any) => {
        try {
            const result = lngDetector.detect(text, 1); // Get only the top result
            return result.length > 0 ? result[0][0] : null; // Extract the language code
        } catch (error) {
            return null;
        }
    };

    const extractTextFromPDFPage = async (file: File): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = async () => {
                const url = fileReader.result as string;
                try {
                    const response = await fetch(url);
                    const buffer = await response.arrayBuffer();
                    const textContent = await extractTextFromBuffer(buffer);
                    resolve(textContent);
                } catch (error) {
                    reject(error);
                }
            };
            fileReader.onerror = () => {
                reject(new Error('Failed to read file'));
            };
            fileReader.readAsDataURL(file);
        });
    };

  // Helper function to extract text from buffer
  const extractTextFromBuffer = async (
    buffer: ArrayBuffer
  ): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const loadingTask = pdfjs.getDocument(buffer);
      loadingTask.promise
        .then((pdf) => {
          console.log(pdf);
          const numPages = pdf.numPages;
          const middlePageIndex = Math.floor(numPages / 2); // Calculate middle page index
          const extractTextFromPage = async (pageIndex: number) => {
            if (pageIndex > numPages) {
              reject(new Error("No page found with enough text."));
              return;
            }
            const page = await pdf.getPage(pageIndex + 1);
            const textContent = await page.getTextContent();
            const pageText = textContent.items
              .map((item: any) => item.str)
              .join(" ");
            const wordCount = pageText.trim().split(/\s+/).length;
            if (wordCount < 4 && pageIndex + 1 > numPages) {
              // If text has less than 4 words, proceed to the next page
              extractTextFromPage(pageIndex + 1);
            } else {
              // Text has enough words, resolve with the text
              resolve(pageText);
            }
          };
          // Start extracting text from the middle page
          extractTextFromPage(middlePageIndex);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

    const handleChange = async (file: any) => {
        setFile(file);
        if (file) {
            const text = await extractTextFromPDFPage(file);
            const detectedLanguage = detectSingleLanguage(text);
            dispatch(setDetectedLanguage(detectedLanguage));
        }
    };

    return (
        <div>
            <div className=' bg-primary-1 p-3 rounded-[20px] flex justify-center items-center mb-5 '>
                <label className='relative cursor-pointer border-white border-dashed border w-full h-[288px] rounded-[20px] flex flex-col justify-center items-center'>
                    <FileUploader handleChange={handleChange} multiple={false} name="file" classes="drag_drop" types={fileTypes}>
                        <div className='flex justify-center items-center flex-col w-full focus:!border-transparent focus-within:!outline-none cursor-pointer'>
                            <span className='mb-6 block !font-mont'><UploadIcons /></span>
                            <h2 className='h5 text-white !font-mont mb-1'>{t('upload_doc')}</h2>
                            <p className='px-[5rem] text-white m-auto text-center w-[450px] md:w-[300px] sm:w-[230px] truncate'>{file ? `${file?.name}` : t('doc_dragDrop')}</p>
                            <p className='text-white m-auto text-center w-[450px] md:w-[300px] sm:w-[230px] truncate'>{description}</p>
                        </div>
                    </FileUploader>
                </label>

            </div>
            <Select
                className="w-full "
                classButton="bg-[#DAF8FF] dark:bg-[#DAF8FF] dark:text-n-6"
                IconComponent={() => <div className=' mr-2'><AudioTimerIcon /></div>}
                items={AudioMinutes.map((minute) => ({
                  ...minute,
                  title: t(`audioMinutes.${minute.value}`),  // Translate the title dynamically
                }))}
                value={minutes}
                onChange={setMinutes}
                placeholder={t('minutes_of_audio')}
                arrowColor={'black'}
            />
            <button className='btn btn-blue w-full mt-5' disabled={!state.file || loading || !minutes} onClick={() => nextClick()}>{loading ? t('analyzing') + '...' : t('next')}</button>
        </div>
    )
}

export default UploadDocument