import React, { useEffect, useState } from "react";
import Switch from "@/components/Switch";
import Checkbox from "@/components/Checkbox";
import { SOCKET_EVENT_TYPES } from "@/constants/notifications";
import { useDispatch, useSelector } from "react-redux";
import { updateNotificationsList } from "store/actions/notification.actions";
import { IUpdateNotificationsList } from "interfaces/notifications.interface";
import { size } from "lodash";
import { useTranslation } from "react-i18next";

type NotificationsProps = {};
const Notifications = ({}: NotificationsProps) => {
  const dispatch: any = useDispatch();
  const { t:lt } = useTranslation('translation');
  const {t} = useTranslation("translation");

  const notificationsData = useSelector(
    (state: any) => state.notificationStore
  );
  const { updateUserNotificationList } = notificationsData;

  const [notifications, setNotifications] = useState<boolean>(false);
  console.log(updateUserNotificationList, " ===> UpdateUserNotificationList");
  const [notilist, setnotilist] = useState<any>([])
  useEffect(()=>{
    setnotilist(updateUserNotificationList?.data?.notificationsList==false ? [] : updateUserNotificationList?.data?.notificationsList)
  },[])

  const trueState = [
    {
      id: "0",
      title: lt('lokichat_platform'),
      checkboxs: [
        {
          id: "0",
          title: "System Notifications",
          key: SOCKET_EVENT_TYPES.SYSTEM_NOTIFICATION,
          isChecked: true,
        },
        {
          id: "1",
          title: "Goal Management Notifications",
          key: SOCKET_EVENT_TYPES.GOAL_NOTIFICATION,
          isChecked: true,
        },
        {
          id: "2",
          title: "Debate Notifications",
          key: SOCKET_EVENT_TYPES.DEBATE_NOTIFICATION,
          isChecked: true,
        },
        {
          id: "3",
          title: "Email Notifications",
          key: SOCKET_EVENT_TYPES.EMAIL_NOTIFICATION,
          isChecked: true,
        },
        // {
        //   id: "4",
        //   title: "Critical Notifications",
        //   key: SOCKET_EVENT_TYPES.CRITICAL_NOTIFICATION,
        //   isChecked: true,
        // },
      ],
    },
  ]

  const falseState = [
    {
      id: "0",
      title: lt('lokichat_platform'),
      checkboxs: [
        {
          id: "0",
          title: "System Notifications",
          key: SOCKET_EVENT_TYPES.SYSTEM_NOTIFICATION,
          isChecked: false,
        },
        {
          id: "1",
          title: "Goal Management Notifications",
          key: SOCKET_EVENT_TYPES.GOAL_NOTIFICATION,
          isChecked: false,
        },
        {
          id: "2",
          title: "Debate Notifications",
          key: SOCKET_EVENT_TYPES.DEBATE_NOTIFICATION,
          isChecked: false,
        },
        {
          id: "3",
          title: "Email Notifications",
          key: SOCKET_EVENT_TYPES.EMAIL_NOTIFICATION,
          isChecked: false,
        },
        // {
        //   id: "4",
        //   title: "Critical Notifications",
        //   key: SOCKET_EVENT_TYPES.CRITICAL_NOTIFICATION,
        //   isChecked: true,
        // },
      ],
    },
  ]
  
  const [checkboxes, setCheckboxes] = useState(trueState);

  // useEffect(()=>{
  //   setNotifications(updateUserNotificationList?.data?.notificationsList == false ? false : true)
  // },[updateUserNotificationList?.data?.notificationsList])

  useEffect(()=>{
    setCheckboxes([
      {
        id: "0",
        title: lt('lokichat_platform'),
        checkboxs: [
          {
            id: "0",
            title: "System Notifications",
            key: SOCKET_EVENT_TYPES.SYSTEM_NOTIFICATION,
            //@ts-ignore
            isChecked: (Array.isArray(updateUserNotificationList?.data?.notificationsList) && updateUserNotificationList?.data?.notificationsList?.includes(SOCKET_EVENT_TYPES.SYSTEM_NOTIFICATION)),
          },
          {
            id: "1",
            title: "Goal Management Notifications",
            key: SOCKET_EVENT_TYPES.GOAL_NOTIFICATION,
            //@ts-ignore
            isChecked: (Array.isArray(updateUserNotificationList?.data?.notificationsList) && updateUserNotificationList?.data?.notificationsList?.includes(SOCKET_EVENT_TYPES.GOAL_NOTIFICATION)),
          },
          {
            id: "2",
            title: "Debate Notifications",
            key: SOCKET_EVENT_TYPES.DEBATE_NOTIFICATION,
            //@ts-ignore
            isChecked: (Array.isArray(updateUserNotificationList?.data?.notificationsList) && updateUserNotificationList?.data?.notificationsList?.includes(SOCKET_EVENT_TYPES.DEBATE_NOTIFICATION)),
          },
          {
            id: "3",
            title: "Email Notifications",
            key: SOCKET_EVENT_TYPES.EMAIL_NOTIFICATION,
            //@ts-ignore
            isChecked: (Array.isArray(updateUserNotificationList?.data?.notificationsList) && updateUserNotificationList?.data?.notificationsList?.includes(SOCKET_EVENT_TYPES.EMAIL_NOTIFICATION)),
          },
          // {
          //   id: "4",
          //   title: "Critical Notifications",
          //   key: SOCKET_EVENT_TYPES.CRITICAL_NOTIFICATION,
          //   //@ts-ignore
          //   isChecked: (Array.isArray(updateUserNotificationList?.data?.notificationsList) && updateUserNotificationList?.data?.notificationsList?.includes(SOCKET_EVENT_TYPES.CRITICAL_NOTIFICATION)),
          // },
        ],
      },
    ]);
  },[updateUserNotificationList])

  useEffect(()=>{console.log(checkboxes, " ===> CheckBoxes States");
  },[checkboxes])

  const handleCheckboxChange = (groupId: string, checkboxId: string) => {
    const updatedCheckboxes = [...checkboxes];
    const groupIndex = updatedCheckboxes.findIndex(
      (group) => group.id === groupId
    );
    const checkboxIndex = updatedCheckboxes[groupIndex].checkboxs.findIndex(
      (checkbox) => checkbox.id === checkboxId
    );
    updatedCheckboxes[groupIndex].checkboxs[checkboxIndex].isChecked =
      !updatedCheckboxes[groupIndex].checkboxs[checkboxIndex].isChecked;
    setCheckboxes(updatedCheckboxes);

    const keysToSend = updatedCheckboxes[0].checkboxs
      .filter((checkbox: any) => checkbox.isChecked === true)
      .map((it) => it.key);
      

    console.log(keysToSend, " ===> Keys to Send");
    if (size(keysToSend) > 0) {
      const data: IUpdateNotificationsList = { notificationsList: keysToSend };
      dispatch(updateNotificationsList(data));
    } else {
      const data: IUpdateNotificationsList = { notificationsList: false };
      dispatch(updateNotificationsList(data));
    }
  };

  const handleNotificationsChange = (value: boolean) => {
    setNotifications(value);
    // const updatedCheckboxes = [...checkboxes];
    // for (let group of updatedCheckboxes) {
    //   for (let checkbox of group.checkboxs) {
    //     checkbox.isChecked = value;
    //   }
    // }
    // const data: IUpdateNotificationsList = { notificationsList: value };

    // console.log(data, " ====> Switch Data");
    

    // setCheckboxes(updatedCheckboxes);
    let data:any;
    if(value == true){
      data = { notificationsList: ['SYSTEM_NOTIFICATION', 'GOAL_NOTIFICATION', 'DEBATE_NOTIFICATION', 'EMAIL_NOTIFICATION'] }
      setCheckboxes(trueState)
      setNotifications(true)
    }else{
      data = { notificationsList: false }
      setCheckboxes(falseState)
      setNotifications(false)
    }
    dispatch(updateNotificationsList(data));
  };

  const handleNotificationsChange1 = (value: boolean) => {
    if(value == true){
      // data = { notificationsList: ['SYSTEM_NOTIFICATION', 'GOAL_NOTIFICATION', 'DEBATE_NOTIFICATION', 'EMAIL_NOTIFICATION'] }
      setNotifications(true)
      setnotilist(['SYSTEM_NOTIFICATION', 'GOAL_NOTIFICATION', 'DEBATE_NOTIFICATION', 'EMAIL_NOTIFICATION'])
    }else{
      // data = { notificationsList: false }
      setNotifications(false)
      setnotilist([])
    }
    // dispatch(updateNotificationsList(data));
  };

  const isAllowToUpdateList = () => {
    // return updateUserNotificationList.loading;
    return false;
  };

  const checkBoxChange = (e:any, it:string) => {
    if(!e?.target?.checked){
      const filtered = notilist?.filter((i:any)=>i!=it)
      setnotilist(filtered)
    }else{
      setnotilist((ps:any) => ([...ps,it]))
    }
  }

  useEffect(()=>{
    if (notilist?.includes('SYSTEM_NOTIFICATION') && notilist?.includes('GOAL_NOTIFICATION') && notilist?.includes('DEBATE_NOTIFICATION') && notilist?.includes('EMAIL_NOTIFICATION')) {
    //@ts-ignore  
    // if (notilist == ['SYSTEM_NOTIFICATION', 'GOAL_NOTIFICATION', 'DEBATE_NOTIFICATION', 'EMAIL_NOTIFICATION']) {
      setNotifications(true);
      console.log("it is running");
    }else{
      setNotifications(false)
    }
    if(notilist?.length > 0){
      dispatch(updateNotificationsList({ notificationsList: notilist }));
    }else{
      dispatch(updateNotificationsList({ notificationsList: false }));
    }
  },[notilist])

  return (
    <form className="" action="" onSubmit={(e) => e.preventDefault()}>
      <div className="flex items-center mb-8">
        <div className="mr-auto h4 !font-mont !font-extrabold">
          {lt('notifications')}
          </div>
        <Switch
          disabled={isAllowToUpdateList()}
          value={notifications}
          // setValue={handleNotificationsChange}
          setValue={handleNotificationsChange1}
        />
      </div>
      <div>
        {/* {checkboxes?.map((group) => (
          <div
            className="mb-8 border-t border-n-3 py-6 last:mb-0 dark:border-n-6"
            key={group.id}
          >
            <div className="mb-4 h6">{group.title}</div>
            {group?.checkboxs?.map((checkbox) => (
              <Checkbox
                disabled={isAllowToUpdateList()}
                className="mb-4 last:mb-0"
                label={checkbox.title}
                key={checkbox.id}
                value={checkbox.isChecked}
                onChange={() => handleCheckboxChange(group.id, checkbox.id)}
                reverse
              />
            ))}
          </div>
        ))} */}
        <div
            className="mb-8 border-t border-n-3 py-6 last:mb-0 dark:border-n-6"
          >
            <div className="mb-4 h6 !font-mont !font-semibold">{lt('lokichat_platform')}</div>
              <Checkbox
                disabled={isAllowToUpdateList()}
                className="mb-4 last:mb-0"
                label={t("system_notifications")}
                // key={checkbox.id}
                value={(Array.isArray(notilist) && notilist.includes('SYSTEM_NOTIFICATION'))}
                // onChange={(e:any) => {console.log(e,e?.target?.value);}}//setnotilist((ps:any)=>ps.filter((i:any)=>i != 'SYSTEM_NOTIFICATION'))
                onChange={(e:any)=>{checkBoxChange(e,'SYSTEM_NOTIFICATION')}}
                reverse
              />
              <Checkbox
                disabled={isAllowToUpdateList()}
                className="mb-4 last:mb-0"
                label={t("goal_management_notifications")}
                // key={checkbox.id}
                value={(Array.isArray(notilist) && notilist.includes('GOAL_NOTIFICATION'))}
                onChange={(e:any)=>{checkBoxChange(e,'GOAL_NOTIFICATION')}}
                reverse
              />
              <Checkbox
                disabled={isAllowToUpdateList()}
                className="mb-4 last:mb-0"
                label={t("debate_notifications")}
                // key={checkbox.id}
                value={(Array.isArray(notilist) && notilist.includes('DEBATE_NOTIFICATION'))}
                onChange={(e:any)=>{checkBoxChange(e,'DEBATE_NOTIFICATION')}}
                reverse
              />
              <Checkbox
                disabled={isAllowToUpdateList()}
                className="mb-4 last:mb-0"
                label={t("email_notifications")}
                // key={checkbox.id}
                value={(Array.isArray(notilist) && notilist.includes('EMAIL_NOTIFICATION'))}
                onChange={(e:any)=>{checkBoxChange(e,'EMAIL_NOTIFICATION')}}
                reverse
              />
          </div>
      </div>
    </form>
  );
};

export default Notifications;
