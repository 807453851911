import React, { useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import Image from "@/components/Image";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";
import Settings from "@/components/Settings";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "@/constants/settings";
import { resetAllUserData, setFiftyPercentOff } from "store/slices/AuthSlice";
import { resetAllChatsData, setAbortChatModal, setAbortNavigationData } from "store/slices/ChatGptSlice";
import { useRouter } from "next/router";
import { resetAllAudioData } from "store/slices/AudioSlice";
import { resetPhotoGenerations } from "store/slices/PhotoGenerationSlice";
import { resetAllGoalData } from "store/slices/GoalSlice";
import { resetImageLibraryData } from "store/slices/ImageLibrarySlice";
import { resetAllPlanData } from "store/slices/PlanSlice";
import { resetAllDevicesData } from "store/slices/DeviceSlice";
import { clearLogoutStorage } from "lib/user";
import { ROUTES } from "@/constants/routes";
import { resetAllDebateData } from "store/slices/DebateSlice";
import { useTranslation } from "react-i18next";
import { PROFILE_URL, SUPER_USER_STATUS, USER_LOCAL } from "@/constants/user";
import { isEmpty, size } from "lodash";
import { resetGetSuperUser } from "store/slices/SuperUserSlice";
import { loadDecryptedValue } from "lib/encryption";
import config from "@/config/index";
import { getSuperUserRequest } from "store/actions/superUser.actions";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { i18n } from "services/i18n";
import { resetTextToSpeechData } from "store/slices/TextToSpeechSlice";
import { resetAllTranscriptionData } from "store/slices/TranscriptionSlice";
import { resetAllTranslations } from "store/slices/TranslationSlice";

type ProfileProps = {};

const Profile = ({}: ProfileProps) => {

  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t: At } = useTranslation('auth');
  const { t: lt } = useTranslation('translation');
  const settings = translate(lt);

  const debateData = useSelector((state: any) => state.debateStore);
  const chatData = useSelector((state: any) => state.chatGptStore);
  const userData = useSelector((state: any) => state.authStore);

  const { user } = userData
  const { updateStream, stream, streamText } = chatData;
  const { updateDebateMessage, updateDebate, updateDebateBotMessage } = debateData;

  const [visibleSettings, setVisibleSettings] = useState<boolean>(false);
  const [superUserBtnContent, setSuperUserBtnContent] = useState('');

  const handleLogout = () => {
    dispatch(resetAllUserData(null));
    dispatch(resetAllChatsData(null));
    dispatch(resetAllAudioData(null));
    dispatch(resetPhotoGenerations(null));
    dispatch(resetAllGoalData(null));
    dispatch(resetImageLibraryData(null));
    dispatch(resetPhotoGenerations(null));
    dispatch(resetAllPlanData(null));
    dispatch(resetAllDevicesData(null));
    dispatch(resetAllDebateData(null));
    dispatch(resetTextToSpeechData())
    dispatch(resetAllTranscriptionData(null))
    dispatch(resetAllTranslations(null))
    dispatch(setFiftyPercentOff(false));
    clearLogoutStorage();
    router.push(ROUTES.SIGNIN);
  }
  const superUserData = useSelector((state: any) => state.superUserStore);
  const { getSuperUser } = superUserData;

  const handleSwitchToSuperUser = () => {
    if(user?.data?.isBlocked) {
      showToast('You are blocked')
      return false;
    }
    if(!isEmpty(getSuperUser.data) && getSuperUser.data.status === SUPER_USER_STATUS.APPROVED) {
      dispatch(resetGetSuperUser(null));
      const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
      const currentMode = localStorage.getItem('chakra-ui-color-mode');
      window.location.href = `${config.superUserDashboardUrl}?token=${sessions.token}&userId=${sessions.userId}&role=${sessions.role}&isDark=${currentMode == 'dark' ? true : false}&refreshToken=${sessions.refreshToken}`;
    } else {
      router.push(ROUTES.SUPER_USER);
    }
  }
  const showToast = (text:any) => {
    setTimeout(() => {
      toast((t) => (
        <Notify iconError>
          <div className="h6 sm:text-sm">{text}</div>
        </Notify>
      ),{duration: 3000});
      
    }, 500);
  };

  useEffect(()=>{
    dispatch(getSuperUserRequest());
  }, [])

  useEffect(() => {
    if(isEmpty(getSuperUser.data) && !isEmpty(getSuperUser.error) && getSuperUser.error === 'no approved request found') {
      setSuperUserBtnContent(At('req_superUser'));
    } else if (!isEmpty(getSuperUser.data) && getSuperUser.data.status === SUPER_USER_STATUS.REJECTED) {
      setSuperUserBtnContent(At('req_superUser'));
    }
    if(!isEmpty(getSuperUser.data) && getSuperUser.data.status === SUPER_USER_STATUS.APPROVED) {
      setSuperUserBtnContent(At('switch_superUser'));
    } else if(!isEmpty(getSuperUser.data) && getSuperUser.data.status === SUPER_USER_STATUS.PENDING) {
      setSuperUserBtnContent(At('superUser_pending'));
    }
  }, [getSuperUser, i18n.language]);

  const menu = [
    // {
    //   title: lt("switch_to_super_user"),
    //   icon: "",
    //   disabled:(getSuperUser.loading || getSuperUser.data?.status === SUPER_USER_STATUS.PENDING),
    //   onClick: () => isChatOrDebateLoading(handleSwitchToSuperUser, null),
    // },
    {
      title: lt('log_out'),
      icon: "logout",
      onClick: handleLogout,
    },
  ];


  const isChatOrDebateLoading = (clickHandler: any, url: any) => {
    try {
      if (stream && size(streamText) >= 0) {
        const data = {
          clickHandler: clickHandler ?? null,
          url: url ?? null
        };
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateStream.loading && size(updateStream.text) >= 0) {
        const data = {
          clickHandler: clickHandler ?? null,
          url: url ?? null
        };
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateDebate.loading || updateDebateMessage.loading || updateDebateBotMessage.loading) {
        const data = {
          clickHandler: clickHandler ?? null,
          url: url ?? null
        };
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else {
        if (clickHandler) {
          clickHandler();
        }
        if(url) {
          router.push(url);
        }
      };
    } catch (error) {

    }

  }

  return (
    <>
      <div className="relative z-10  lg:mr-6 md:static">
        <Menu>
          <Menu.Button className="group relative w-9 h-9 rounded-full transition-shadow ui-open:shadow-[0_0_0_0.25rem_#0084FF] block">
            <Image
              className="rounded-full object-cover"
              src={user?.data?.profileUrl ?? PROFILE_URL}
              fill
              alt="Avatar"
            />
          </Menu.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="absolute top-full -right-5 w-[23.5rem] md:w-[calc(100vw-6rem)] mt-[0.9375rem] p-4 bg-n-1 border border-n-2 rounded-2xl shadow-[0px_48px_64px_-16px_rgba(0,0,0,0.25)] md:-right-[4.5rem] dark:bg-n-7 dark:border-n-5">
              <div className="flex items-center mb-3">
                <div className="relative w-15 sm:w-10 h-15 sm:h-10 flex-shrink-0">
                  <Image
                    className="rounded-full object-cover"
                    src={user?.data?.profileUrl ?? PROFILE_URL}
                    fill
                    alt="Avatar"
                  />
                </div>
                <div className="pl-4">
                  <div className="h6 w-[200px] truncate">{user?.data?.userName}</div>
                  <div className="caption1 text-n-4">
                    {user?.data?.email}
                  </div>
                </div>
              </div>
              <div className="overflow-hidden bg-n-2 rounded-xl dark:bg-n-6">
                {menu?.map((item, index) => (
                  <Menu.Item key={index}>
                    <button
                      className={`${index==0? "bg-primary-1 hover:opacity-70 text-white":"hover:text-primary-1"} group relative px-4 flex items-center w-full h-12 base2 font-semibold transition-colors `}
                      onClick={item.onClick}
                      // onClick={item?.disabled ? ()=>{} : item.onClick}
                      // disabled={item?.disabled}
                    >
                      {
                        index==0&&
                        <span className="absolute top-1/2 !font-mont -translate-y-1/2 right-3">
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#E8ECEF"/></svg>
                        </span>
                      }
                      {index==0? (
                        <div className="w-3 h-3 bg-n-4  mr-5 ml-2 rounded-full"></div>
                      ) : (
                        <Icon
                          className="mr-4 fill-n-4 transition-colors group-hover:fill-primary-1"
                          name={item.icon}
                        />
                      )}
                      {item.title}
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <Modal
        className="md:!p-0"
        classWrap="max-w-[48rem] md:min-h-screen-ios md:rounded-none"
        classButtonClose="hidden md:block md:absolute md:top-5 md:right-5 dark:fill-n-4"
        classOverlay="md:bg-n-1"
        visible={visibleSettings}
        onClose={() => setVisibleSettings(false)}
      >
        <Settings items={settings} />
      </Modal>
    </>
  );
};

export default Profile;
