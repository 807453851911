import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { RiArrowDownSFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setMoveDocumentListData, setMoveDocumentModal } from "store/slices/TranslationSlice";
import { useTranslation } from "react-i18next";
import { useRouter } from 'next/router';
import { ROUTES } from "@/constants/routes";
import { size } from "lodash";
import { BookmarkIcon } from "@/components/SVGs";


function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const MoveChatDropdown = (props: any) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation('translation');

  const { pathname } = router;
  const { documentId, chatList, chatButton, showDropDown } = props;

  const chatData = useSelector((state: any) => state.chatGptStore);
  const documentData = useSelector((state: any) => state.translationStore);
  const {  stream, updateStream } = chatData;
  const {storedDocumentLists} = documentData;

  const [showMoveChat, setShowMoveChat] = useState(false);

  useEffect(() => {
    handleShowMoveChat();
  }, [router])

  const handleShowMoveChat = () => {
    if (pathname == ROUTES.CHAT || pathname === ROUTES.CHAT_ID || pathname === ROUTES.CHAT_LIST_ID || pathname === ROUTES.CHAT_LIST || pathname === ROUTES.CHAT_LIST) {
      setShowMoveChat(true);
    } else {
      setShowMoveChat(false);
    }
  }

  const handleMoveDocumentList = (event: any, item: any) => {
    event.preventDefault();
    dispatch(setMoveDocumentModal(true));
    const data = { documentId: documentId, documentListId: item.id, documentlist: item };
    
    dispatch(setMoveDocumentListData(data));
  }

  const showMoveDropDown = () => {
    const result = storedDocumentLists.data.find((el: any) => el.id === chatList);
    return result && size(storedDocumentLists.data) > 1 || !result && size(storedDocumentLists.data) > 0;
  }

  const isChatStreamLoading = () => {
    return stream || updateStream.loading;
  }

  return (
    <>
      {(storedDocumentLists && showMoveDropDown() && storedDocumentLists.data && size(storedDocumentLists.data) > 0) && <Menu as="div" className="relative text-left">
        {(showMoveChat || showDropDown) &&
         <Menu.Button disabled={isChatStreamLoading()} className={`${chatButton ? "rounded-[12px] h-[60px] bg-[rgba(255,216,141,0.50)] sm:w-[312px] plg:w-[328px] md:w-[655px] dark:border-n-5 ss:gap-1 !text-sm ss:!text-[10px] whitespace-nowrap" : "bg-white"} sm:w-[312px] w-[240px] dark:bg-[rgba(255,216,141)] font-[600] dark:text-white hover:opacity-80 rounded-[12px]  flex items-center  h-6 px-2 text-xs gap-2`}>
          <BookmarkIcon/>
          {/* <span className="!font-mont text-[#444]">{(chatButton ? "Save in a Doc List" : "Save in a Doc List")}</span> */}
          <span className="!font-mont text-[#444] overflow-hidden">{t("save_in_a_doc_list")}</span>

        </Menu.Button>
        }

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={`${chatButton ? "w-[238px] max-h-[250px]" : "w-[190px]  max-h-[200px]"} select-document-dropdown  rounded-lg overflow-auto bg-white dark:bg-n-5 absolute z-10 mt-1 right-0 shadow-xl`}>
            <p className="text-n-6 dark:text-n-3 text-sm !font-mont font-semibold px-4 pt-4 py-2">{t("select_document_list")}</p>
            {storedDocumentLists?.data?.map((item: any, idx: number) => {
              return item.id != chatList && (
                <Menu.Item key={idx}>
                  {({ active }) => (
                    <button
                      key={item.title}
                      onClick={(event) => handleMoveDocumentList(event, item)}
                      className={classNames(active ? "" : "", "flex gap-3 items-center text-sm text-n-4 dark:text-white hover:opacity-90 p-3 bg-transparent hover:bg-n-2 dark:hover:bg-n-4 w-full")}
                    >
                      <div className="w-3 h-3 border border-n-4 dark:border-n-3 shrink-0" style={{ background: item.color }}></div>
                      <p className="truncate">{item.title}</p>
                    </button>
                  )}
                </Menu.Item>
                
              )
            })}
          </Menu.Items>
        </Transition>
      </Menu>}
    </>
  );
}

export default MoveChatDropdown;