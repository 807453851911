import React, { useState, useEffect } from "react";
import Image from "@/components/Image";
import Document from "./Document";
import { useDispatch, useSelector } from "react-redux";
import { abortRegenerateOrUpdate, resetChatLoadingAndError } from "store/slices/ChatGptSlice";
import { useTranslation } from "react-i18next";
import { updateStream } from "services/chat.fetch.service";
import { ABORT_EVENTS } from "@/constants/chats";
import QuestionsTextarea from "../QuestionsTextarea";
import { isEmpty, size } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { stopMessageStream, updateMessageStream } from "store/actions/chat.actions";
import { ErrorToast } from "@/constants/toast";
import { PROFILE_URL } from "@/constants/user";

type QuestionProps = {
  content: any;
  image?: string;
  document?: string;
  time: string;
  question?: any;
  selectedMessage?: any;
  setUpdatePromptData?: any;
  allowEditQuestion?: boolean;
  fromGuest?: string;
};

let abortController = new AbortController();

const Question = ({
  content,
  image,
  document,
  time,
  question,
  selectedMessage,
  setUpdatePromptData,
  allowEditQuestion,
  fromGuest
}: QuestionProps) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation('translation');

  const chatData = useSelector((state: any) => state.chatGptStore);
  const userData = useSelector((state: any) => state.authStore);

  const { stream, createChat, updateStream: updateStreamData } = chatData;
  const { user } = userData;

  const [allowEdit, setAllowEdit] = useState(false);
  const [updatedContent, setUpdatedContent] = useState(content?.props?.dangerouslySetInnerHTML?.__html);
  const [originalContent, setOriginalContent] = useState(content?.props?.dangerouslySetInnerHTML?.__html);
  const [messagePassCode, setMessagePassCode] = useState<any>('');
  const [abortUpdateChat, setAbortUpdateChat] = useState<any>(null);

  useEffect(()=> {
    setUpdatedContent(content?.props?.dangerouslySetInnerHTML?.__html);
    setOriginalContent(content?.props?.dangerouslySetInnerHTML?.__html);
  }, [content?.props?.dangerouslySetInnerHTML?.__html]);

  const resetContent = () => {

    setUpdatedContent(originalContent); // Reset the updated content
    setOriginalContent(originalContent); // Update the original content
    setAllowEdit(false);
  };


  const handleEditQuestion = (event: any) => {
    try {
      const plainUpdatedContent = event.target?.value;
      setUpdatedContent(plainUpdatedContent);
      if (event.key === "Enter" && !event.shiftKey && plainUpdatedContent && (size(plainUpdatedContent?.trim()) > 0)) {
        setAllowEdit(false);
        setUpdatePromptData(selectedMessage);
        const data: any = {
          chatId: createChat.data?.id,
          messageId: selectedMessage.messageId,
          message: plainUpdatedContent?.trim(),
        };
        const passcode = uuidv4();
        data.messagePassCode = passcode;
        setMessagePassCode(selectedMessage.messageId);
        dispatch(updateMessageStream(data)).unwrap().then((res: any) => {
          console.log('res-update', res);
          localStorage.setItem('isUpdate', 'true');
          localStorage.setItem('chatId', createChat.data?.id);
          localStorage.setItem('messageId', selectedMessage.messageId);
          dispatch(updateStream(data, abortController.signal));
          setAbortUpdateChat(true);
        }).catch((error: any) => {
          ErrorToast(error?.message);
        });
        setUpdatedContent(plainUpdatedContent?.trim());
      }
      else if(event.key === "Enter" && !plainUpdatedContent) {
        event.preventDefault();
      }
    } catch (error) {
      setAbortUpdateChat(true);
    }
  };

  const handleUpdateResponse = () => {
    if(updatedContent && size(updatedContent?.trim()) > 0) {
      setAllowEdit(!allowEdit);
      setAllowEdit(false);
      setUpdatePromptData(selectedMessage);
      updateChat();
    }
  };

  const updateChat = () => {
    const data: any = {
      chatId: createChat.data?.id,
      messageId: selectedMessage.messageId,
      message: updatedContent?.trim(),
    };

    dispatch(updateMessageStream(data)).unwrap().then((res: any) => {
      console.log('res-update', res);
      localStorage.setItem('isUpdate', 'true');
      localStorage.setItem('chatId', createChat.data?.id);
      localStorage.setItem('messageId', selectedMessage.messageId);
      dispatch(updateStream(data, abortController.signal));
      setAbortUpdateChat(true);
    }).catch((error: any) => {
      ErrorToast(error?.message);
    });

    setMessagePassCode(selectedMessage.messageId);
    setOriginalContent(updatedContent?.trim());
  };

  useEffect(() => {
    try {
      if (
        chatData.stopGenerateChat?.keyName === ABORT_EVENTS.UPDATE &&
        chatData.stopGenerateChat?.value &&
        abortUpdateChat
      ) {
        console.log('Called-2');
        setAbortUpdateChat(false);
        abortController.abort('Chat Paused with user permission');
  
        abortController = new AbortController();
        const data = {
          messageId: messagePassCode,
          chatId: createChat.data?.id ?? null,
          isUpdate: true,
        };
        if(!isEmpty(createChat?.data?.id)) {
          dispatch(stopMessageStream(data)).unwrap().then((res: any)=>{
            console.log("test", res);
          });
        }
        setUpdatePromptData({});
        dispatch(abortRegenerateOrUpdate(null));
        dispatch(resetChatLoadingAndError(null));
      }
    } catch (error) {
      
    }
    
  }, [chatData.stopGenerateChat]);

  return (
    <div className="md:w-[calc(100%-30px)] 2xl:w-[calc(100%-80px)] w-[calc(100%-170px)] ml-auto">
      <div className={`space-y-6 pt-4 px-5 pb-8 border-3 border-n-2 rounded-[1.25rem] md:p-5 md:pb-14 break-words ${!fromGuest && 'dark:border-transparent dark:bg-n-5/50'}`}>
        {document && <Document value={document} />}
        {allowEdit && (
          <QuestionsTextarea
            allowEdit={allowEdit}
            handleEditQuestion={handleEditQuestion}
            value={updatedContent}
            onKeyDown={handleEditQuestion}
            onKeyUp={handleEditQuestion}
        />
        )}
        {!allowEdit &&
          <div className={` whitespace-pre-line ${fromGuest && 'text-n-4'}`}>
            {updatedContent}
          </div>
        }
        {image && (
          <div className="relative w-[11.25rem] h-[11.25rem]">
            <Image 
              className="rounded-xl object-cover"
              src={image ?? PROFILE_URL}
              fill
              alt="Avatar"
            />
          </div>
        )}
      </div>
      <div>
        {allowEdit ? (
          <div className="flex items-end pr-6 mt-2">
            <button
              onClick={resetContent}
              className="ml-3 px-2 py-0.5 bg-n-3 rounded-md caption1 txt-n-6 transition-colors dark:bg-n-5/50"
            >
              {t('discard')}
            </button>
            <button
              disabled={stream || updateStreamData?.loading}
              onClick={() => {
                handleUpdateResponse();
              }}
              className="ml-3 px-2 py-0.5 rounded-md caption1 text-n-3 transition-colors bg-primary-1"
            >
              {t('update_btn')}
            </button>
          </div>
        ) : (
          <div className="-mt-8 flex items-end pr-6">
            <div className={`pb-0.5 caption1 text-n-4/50 ${!fromGuest && 'dark:text-n-4'}`}>
              {time}
            </div>
            {!isEmpty(selectedMessage?.isEditedAt) && selectedMessage.isEditedAt?.isEdited &&
              <div className={`pb-0.5 pl-1 caption1 text-n-4/50 ${!fromGuest && 'dark:text-n-4'}`}>
                {'Edited'}
              </div>
            }
            {question && (selectedMessage?.type != 'SUMMARY') && !selectedMessage?.message?.includes('Summary for') && (
              <button
                disabled={allowEditQuestion || stream || updateStreamData.loading}
                onClick={() => setAllowEdit(!allowEdit)}
                className="ml-3 px-2 py-0.5 bg-n-3 rounded-md caption1 txt-n-6 transition-colors hover:text-primary-1 dark:bg-n-5/50"
              >
                {t('edit')}
              </button>
            )}

            <div className={`relative w-16 h-16 ml-auto rounded-2xl overflow-hidden shadow-[0_0_0_0.25rem_#FEFEFE] ${!fromGuest && 'dark:shadow-[0_0_0_0.25rem_#232627]'}`}>
              <Image
                className="object-cover"
                src={user.data?.profileUrl ?? PROFILE_URL}
                fill
                alt="Avatar"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Question;
